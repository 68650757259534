.library-slides-modal,
.clone-slides-modal {
  .row{
    margin-left: 0;
    margin-right: 0;
  }


  .select-btn-grp,.modal-footer{
    .btn{
      margin-left: 2rem;
    }

    .btn-outline{
      background-color: transparent;
      padding: 2px 5px;
      border: 2px solid #c9c9c9;
      border-radius: 5px;
      min-width: 120px;
      color: #a8a8a8;
  
      &:hover{
        background-color: #dfdfdf;
        transition: background-color 0.25s;
      }
      
      &:focus,
      &:active:focus {
         border: 2px solid #c9c9c9;
        box-shadow: initial;
      }
    }

    .btn-primary{
      border: none;
      background-color: #1d9bf1;
      padding: 2px 5px;
      border-radius: 5px;
      min-width: 120px;
      min-height: 38px;
      transition: background-color 0.25s;
  
      &:hover{
        background-color: #1e86cc;
        transition: background-color 0.25s;
      }
  
      &:focus,
      &:active,
      &:active:focus {
        border: none;
        box-shadow: initial;
      }
    }
  }


  .modal-dialog {
    width: 100%;
    min-height: 100%;
    max-width: 100%;
    margin: 0;
    
    .modal-content {
      border-radius: 0;
      border: 0;
      min-height: 100vh;
    }
    
    .modal-header {
      display: block;
      position: relative;
      padding: 1rem 0 0 0;
      border: 0;

      .modal-close-btn{
        @include btn-reset();
        width: 50px;
        height: 50px;
      }
    }
    
    .modal-header .close {
      position: absolute;
      top: 0;
      right: 0;
      margin: auto;
      height: 100%;
    }

    .modal-footer{
      .container{
        margin-right: auto;
        margin-left: auto;
        display: flex;
        justify-content: flex-end;

      }
    }
  }

  .library-presentations {
    margin-bottom: 2em;

    .filter-grp{
      justify-content: flex-end;

      .library-categories-container{
        @media (min-width: $bp-lg) {
          min-width: 150px;
          max-width: 170px;
        }
        .category-selector{
          width: 100%;
          position: relative;
          z-index: 99999;
          border-bottom: 1px solid #cccccc;
          font-size: 0.7rem;

          &>div{
              border: none;
          }
        }
      }

      &>div:nth-of-type(2){
        @media (min-width: $bp-lg) {
          min-width: 110px;
          max-width: 130px;
        }
      }

      .favourite-btn{
        @include btn-reset();
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.25rem 0.3rem 0.25rem;
        border-bottom: 1px solid transparent;
        width: 100%;
        min-height: 37px;
        font-size: 0.7em;

        span{
          color: #333;
          font-weight: 400;
        }
  
        svg {
          color: #4a4b4d;
          height: 18px;
          min-width: 24px;
        }
  
        &:nth-of-type(1) {
            border-bottom: 1px solid #d2d0cf;
          &.active {
            &>svg {
              fill: #4a4b4d;
            }
          }
        }
      }
    }

    .presentation-filters{

      &>div>div{
        padding-right: 0 !important;
      }

      .search input::placeholder, .dropdown .category-selector, .button-group button{
        font-size: .55rem;
      }

      .search-container input{
        margin-bottom: 5px;
      }
      
      .button-group{
        display: flex;
        align-items: center;
        padding-left: 0 !important;
        
        &>.row{
          width: 100%;
          padding-bottom: 4px;
          
          .col{
            padding-right: 0 !important;
            
            button{
              &.reset{
                padding-top: 5px;
                padding-bottom: 5px;
              }
              &.apply{
                padding-top: 7px;
                padding-bottom: 7px;
              }
            }
          }
        }
      }

      .filter-column{
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        @media (min-width: $bp-xxl) {
            width: 15%;
        }
        @media (max-width: $bp-lg) {
            width: 33%;
        }
        .category-selector{
          width: 100%;
        }
      }
    }
  }

  .slide-selection{
    .card-group{
      background-color: rgba(30, 156, 241, 0.08);
    }
  }

  .presentation-info{
    background-color: rgba(30, 156, 241, 0.08);
  }
  
  .card-group{
    flex-flow: wrap;
    .card{
      flex: none;
      width: 8%;
      margin: .5rem 1% .5rem 1%;
      border: none;
      
      button, .slide-item-card {
        @include btn-reset();
  
        .card-body,
        .card-footer {
          padding: 0 !important;
          text-align: left;
        }
        
        
        .card-title {
          color: $normal-font-colour;
          font-size: 0.8em;
          margin: 0;
          font-size: 0.5em;
          
          .title {
            margin: 0;
          }
        }
        
        .card-body {
          flex: 1 1 auto;
          padding: 1rem 1rem;
          height: 40px;
          overflow: auto;
        }
  
        .card-footer{
          background-color: transparent;
          border: 0;
          border-bottom: 1px solid #777;
          border-radius: 0;

          .text-muted {
            text-align: right;
            width: 100%;
            display: block;
            font-size: 0.5rem;
          }
        }
      }
    }
  }
  .presentation-item,
  .slide-item {
    position: relative;

    &.selected {
      .selected-icon {
        opacity: 1;
        @include transition(all .5s ease-in-out);
      }
    }

    .selected-icon {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      width: 20px;
      height: 20px;

      @include transition(all .5s ease-in-out);

      .icon,
      .tri {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 20px;
        height: 20px;
      }

      .icon {
        z-index: 2;
        
        svg{
          color: #fff !important;
        }
      }

      .tri {
        z-index: 1;
        width: 0;
        height: 0;
        border-width: 17px;
        border-color: #4caf50 #4caf50 transparent transparent;
        border-style: solid;
        border-top-right-radius: 3px;
      }
    }

    button{
      @include btn-reset();
    }


  }

  .slide-item{
    .card-footer{
      text-align: end !important;      

      .slide-item-btn{
        @include btn-reset();
        width: 10px;
        height: 10px;
        border-radius: 3px !important;
        border: 1px solid #1d9bf1 !important;
        margin-right: 5px;
        &:hover{
          background-color: rgba(30, 156, 241, 0.08) !important;
        }
        &.selected{
          background-color: #1d9bf1 !important;
        }
      }

    }
  }

  
  .pagination-container{
    .btn{
      @include btn-reset();
      color: #555;
      border-radius: 5px;
      margin: 0 10px;
      
      &.active{
        color: #ffffff;
        background-color: #1d9bf1;
      }
    }
  }
}
.media-library-modal {
  .modal-header {
    position: relative;
    background-color: rgba(30, 156, 241, 0.08);

    .modal-title {
      margin-right: 20px;
    }

    .modal-close-btn {
      @include btn-reset();
      padding: 0;
      width: 50px;
      height: 50px;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .media-library-title {
    width: auto;
    white-space: nowrap;
    font-size: 30px;
  }

  .add-btn {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;

    svg {
      width: 80%;
      height: auto;
    }
  }

  .add-btn-label {
    width: auto;
    white-space: nowrap;
    font-size: 16px;
    margin-left: 10px;
    font-weight: 400;
  }

  .media-type-filters {
    border: 1px solid $primary-colour;
    border-radius: 10px;

    button {
      text-transform: uppercase;
      font-size: 0.75rem;
    }
  }

  @media (min-width: 1200px) {
    .filter-input {
      width: 15.27%;
      flex: 0 0 auto;
      &.reset {
        width: 7.14%;
      }
    }
  }

  @media (min-width: 768px) {
    .filter-input-container {
      justify-content: center !important;

      .filter-input.reset {
        width: auto;
        flex: 0 0 auto;
        .reset-btn {
          width: 100%;
          margin-right: -16px;
        }
      }
    }
  }

  @media (min-width: 576px) {
    .filter-input-container {
      justify-content: start;
      .filter-input.reset {
        justify-content: start !important;
        .reset-btn {
          width: 50%;
          margin-left: 8px;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .filter-input.reset {
      flex: 0 0 auto;
      width: 100%;
      .reset-btn {
        width: 100%;
        margin-right: -16px;
      }
    }
  }

  .filter-input.reset {
    .reset-btn {
      height: 36px;
    }
  }
  .media-library {
    .media-selector {
      @include btn-reset();

      border-radius: 10px;
      margin: 5px;

      img {
        border-radius: 10px;
      }

      &.type-audio {
        min-height: 150px;
        border: 1px solid $border-colour;
        opacity: 0.7;
        @include transition(0.25s ease-in-out);

        &:hover {
          opacity: 1;
          @include transition(0.25s ease-in-out);
        }

        audio {
          width: 90%;
          margin: 0 auto;
        }

        span {
          color: #555;
          display: block;
          font-size: 14px;
          width: 90%;
          margin: 15px auto 0;
          line-height: 18px;
        }

        svg {
          color: #555;
        }
      }

      &.type-video {
        background-color: lightgray;
        transition: background-color 0.3s;
        padding: 2rem;

        &:hover {
          background-color: #585085;
        }

        video {
          width: 100%;
        }
      }
    }

    .media-container {
      .react-photo-gallery--gallery {
        .gallery-element {
          max-width: 700px;
          max-height: 700px;
          padding: 0;
          background-color: transparent;
          border: none;
          outline: none;
          border-radius: 3px;
          margin: 2px;
          position: relative;
          overflow: hidden;

          &:focus,
          &:active,
          &:active:focus {
            background-color: transparent;
            border: none;
            box-shadow: initial;
          }

          &:hover {
            img,
            video {
              transform: scale(1.15);
            }

            .element-name {
              background: rgba(0, 0, 0, 0.75);
            }
          }

          img,
          video {
            position: relative;
            transition: all 0.25s ease-in-out;
          }

          video {
            width: 100%;
          }

          .video-icon {
            width: 30%;
            height: 30%;
          }

          .audio-icon {
            width: 30%;
            height: 30%;
            fill: #16324f;
          }

          &.video {
            background-color: #b9f3cd;
            transition: background-color 0.1s;

            &:hover {
              transition: background-color 0.1s;
              background-color: #82d8a0;
            }

            img {
              position: absolute !important;
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: flex;
              justify-content: center;
              align-items: center;
              color: transparent;
            }

            .video-overlay-indicator {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.1);
              display: flex;
              justify-content: flex-end;
              align-items: flex-start;

              .video-icon-indicator {
                width: 20%;
                position: absolute;
                top: 3%;
                right: 3%;
                height: auto;

                &, *{
                  fill: #fff;
                }
              }
            }
          }

          &.audio {
            background-color: #d9eefd;
            transition: background-color 0.1s;

            &:hover {
              transition: background-color 0.1s;
              background-color: #7cc1f1;
            }
          }

          .element-name {
            font-size: 0.6rem;
            width: 100%;
            position: absolute;
            bottom: 0;
            background: rgba(75, 76, 78, 0.5);
            color: white;
            padding: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: all 0.25s ease-in-out;
            -webkit-line-clamp: 1;
          }
        }
      }

      .pagination-container {
        .btn {
          @include btn-reset();
          color: #555;
          border-radius: 5px;
          margin: 0 10px;

          &.active {
            color: #ffffff;
            background-color: #1d9bf1;
          }
        }
      }
    }

    p {
      margin: 0;
    }

    .alert {
      .btn-close {
        right: 10px;
        bottom: 0;
        margin: auto;
      }
    }

    .ps-media-toolbar {
      border-bottom: 1px solid $border-colour;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 20px;

      button {
        @include btn-reset();
        margin-right: 10px;

        color: #999;

        &:hover,
        &.active {
          color: #000;
        }
      }
    }

    .video-item {
      width: 100%;
    }

    .crop-container {
      text-align: center;
      background-color: #e1e1e1;
      position: relative;
      overflow: hidden;

      .ReactCrop {
        max-height: 500px;
      }

      .cropper {
        line-height: 0; // removes auto spacing from container
        min-height: 500px;
        max-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .actions {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 16px;
        display: flex;
        justify-content: center;

        opacity: 0.9;
        @include transition(all 0.3s ease-in-out);

        &:hover {
          opacity: 1;
          @include transition(all 0.3s ease-in-out);
        }
      }
    }

    .img-preview {
      line-height: 0; // removes auto spacing from container
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e1e1e1;
      border: 1px solid $border-colour;
      min-height: 500px;
      max-height: 500px;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 500px;
      }
    }

    .info-container {
      min-height: 500px;
      max-height: 500px;

      .wrapper {
        border: 1px solid $border-colour;
        padding: 20px;
        min-height: 100%;
        overflow: auto;
        position: relative;

        .info-loader {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 99;
        }
      }
    }

    .img-info {
      margin-top: 1rem;

      .rw {
        margin: 5px 0 0;
        width: 100%;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 4px;

        label {
          width: 100px;
          text-align: center;
          margin-right: 10px;
          background-color: #e9e9e9;
          padding: 5px;
          font-size: 0.8rem;
          font-weight: bold;
        }
      }
    }
  }

  .crop-actions {
    display: flex;
    justify-content: center;
    align-items: center;

    &.singular {
      height: 42px;
    }

    .back-btn {
      position: absolute;
      left: 0;

      .MuiBox-root {
        background-color: $ps-brand;
        padding: 4px;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        margin-right: 12px;
        display: flex;
      }

      svg {
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .selected-audio {
    .controls-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .text-center {
        width: 60%;

        audio {
          width: 100%;
          border-radius: 5px;
        }
      }
    }
  }
}

.filepond-modal {
  .filepond--credits {
    display: none;
  }

  .filepond--root {
    margin-bottom: 0;
  }
}

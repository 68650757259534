.inline_image_block.content_block,
.ce-block.inlineImage {
  display: inline-block;
  min-width: 100px;
  min-height: 100px;
  padding-right: 20px;
  padding-bottom: 20px;

  .ce-block__content {
    min-width: 100px;
    min-height: 100px;

    .cdx-inline-media-wrapper {
      object-fit: cover;
    }

    .cdx-dim-btn {
      top: 20px;
      right: 48px;
    }

    .cdx-dim-modal-main {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.75);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .cdx-dim-modal-inner {
        position: relative;
        width: 410px;
        min-height: 140px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 50px;
        background: #fff;
        border-radius: 15px;

        .cdx-dim-label,
        .cdx-dim-input {
          max-width: 25%;

          &.cdx-dim-label {
            font-weight: 600 !important;
            color: #1d9bf1 !important;
          }

          &.cdx-dim-input{                
            border: none;              
            border-bottom: 1px solid #dedede;
          }
        }
      }
    }
  }
}

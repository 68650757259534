.ce-block {
  &.gallery {
    width: 80%;
    margin: 20px auto 0;
  }
}

.cdx-gallery-container {
  overflow: auto;
  max-height: 600px;

  .cdx-gallery {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 25px;
    justify-content: flex-start;

    .cdx-gallery-item-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: 20%;
      margin: 10px;
      position: relative;
      background-color: #f9f9f9;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-radius: 4px;
      box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
      max-width: 345px;

      .cdx-gallery-item-caption {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.4375em !important;
        letter-spacing: 0.00938em !important;
        color: rgba(0, 0, 0, 0.87) !important;
        box-sizing: border-box;
        position: relative !important;
        cursor: text;
        border-radius: 4px;
        border: 1px solid #ebebeb;
        max-height: 60px;
        overflow: auto;
        display: block;
        margin: 10px;
        padding: 5px;
        height: 60px;

        &[data-placeholder] {
          &:empty {
            &:before {
              content: attr(data-placeholder);
              color: #707684;
              font-weight: normal;
              opacity: 1;
              transition: all 0.1s ease-in-out;
            }
          }

          &:before {
            opacity: 1;
            transition: all 0.1s ease-in-out;
          }
        }
      }

      .cdx-gallery-btn-group {
        display: flex;
        justify-content: space-between;
        margin: 0 10px 10px;

      }

      &:first-of-type {
        .cdx-gallery-btn-group {
          button {
            &:first-of-type {
              pointer-events: none;
              opacity: 0.25;
            }
          }
        }
      }

      &:last-of-type {
        .cdx-gallery-btn-group {
          button {
            &:last-of-type {
              pointer-events: none;
              opacity: 0.25;
            }
          }
        }
      }
    }
  }

  .cdx-gallery-add-btn {
    margin: 10px 35px;
    font-size: 14px;
    font-weight: bold;
  }
}
// EVENTS SINGLE

.single {
  &.event {
    .article {
      .event-details {
        margin-bottom: 2rem;
        border-bottom: 1px solid $border-colour;

        .event-details__header {
          margin-bottom: 1rem;
          padding-bottom: 0.25rem;
          border-bottom: 1px solid $border-colour;
        }

        .event-details__item {
          margin-bottom: 1rem;

          svg {
            display: block;
            margin-right: 0.5rem;
            color: $primary-colour;
          }

          a,
          p {
            display: block;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

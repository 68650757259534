.ce-tune-font-color--black{
  color:black
}
.ce-tune-font-color--white{
  color:white
}
.ce-tune-font-color--red{
  color:red
}
.ce-tune-font-color--green{
  color:green
}
.ce-tune-font-color--blue{
  color:blue
}
.ce-tune-font-color--yellow{
  color:yellow
}
$width: 1366px;
$height: 768px;

[data-page="thumbnail-presentations"].content-container {
    margin: 0;
    padding: 0;
    width: 1366px;
    min-width: 1366px;
    max-width: 1366px;
    height: 768px;
    // .slides{
    //     width: 1366px;
    //     height: 768px;
    //     inset: 50% auto auto 50%;
    //     transform: translate(-50%, -50%) scale(1) !important;
    // }
    .slide-number{
        display: none !important;
    }
    .controls-arrow{
        opacity: 0;
    }
    .fragment{
        visibility: visible !important;
        transform: none !important;
        opacity: 1 !important;
    }
}

.thumbnail-presentation {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    .slide-wrap {
        .slide {

            .codex-editor {
                width: $width;
                height: $height;
                max-width: $width;
                max-height: $height;
            }

            .table {
                position: relative;
                width: 100%;
                height: 100%;
                display: grid;
                font-size: 14px;
                border-top: 1px solid black;
                line-height: 1.4;

                .table-row {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
                    position: relative;
                    border-bottom: 1px solid black;

                }
            }

            .video_block {
                video {
                    width: 100%;
                }
            }

            span {
                &.XL {
                    font-size: 32px !important;
                }

                &.L {
                    font-size: 28px !important;
                }

                &.S {
                    font-size: 20px !important;
                }

                &.XS {
                    font-size: 16px !important;
                }
            }

            .gallery-counter {
                float: right;
                padding-bottom: 10px;
                font-size: 20px;
            }

            .slick-slider {
                clear: both;

                .slick-arrow {
                    &::before {
                        color: #3f3f3f;
                        opacity: 1;
                    }
                }

                .slick-list {
                    // background-color: #3f3f3f;

                    .slick-slide {
                        .gallery_image {
                            display: flex !important;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start !important;
                            width: 100%;
                            height: 600px;
                            max-height: 600px;
                            opacity: 0.2;
                            transition: opacity 300ms ease-in-out;

                            img {
                                width: 100%;
                                height: 500px;
                                max-height: 500px;
                            }

                            span {
                                padding-top: 20px;
                                line-height: 1.6;
                                font-size: 24px;
                                color: #53565a;
                                opacity: 0;
                                transition: opacity 300ms;
                            }
                        }

                        &.slick-active {
                            .gallery_image {
                                opacity: 1;
                            }

                            span {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .ps-viewer {
        .reveal {
            .slide-number {
                top: 10px;
                bottom: auto;
            }
        }
    }
}
$width: 1366px;
$height: 768px;
$spacing: 15px;
$gap: calc($header-height-xl + $spacing * 2);

.edit-presentation {
  .codex-editor {
    div[hidden] {
      display: none !important;
    }
  }

  & * {
    -webkit-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
  }

  .ce-block {
    span {
      &.XL {
        font-size: 32px !important;
      }

      &.L {
        font-size: 28px !important;
      }

      &.S {
        font-size: 20px !important;
      }

      &.XS {
        font-size: 16px !important;
      }
    }

    .cdx-simple-video-container {
      width: 100%;

      video {
        width: 100%;
      }
    }
  }

  .ce-toolbar {
    z-index: 99 !important;
  }

  padding: 0;
  padding-top: $header-height-xl;
  height: 100%;
  position: relative;

  .ps-viewer {
    margin: 0;
    background-color: #f1f1f1;
    padding: 0;
    position: relative;

    .edit-container {
      height: calc(100% - 80px);
      margin: 0;
      // padding: 30px;
    }

    .reveal {
      .slide-number {
        top: 10px;
        bottom: auto;
      }
    }
  }

  .slide-wrap {
    position: relative;

    &.saving {
      .slide {
        opacity: 0.85;
      }
    }

    .slide {
      .codex-editor {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .library-slide-indicator {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;

      .icon,
      .tri {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
      }

      .icon {
        z-index: 2;
        transform: rotate(-45deg);
      }

      .tri {
        z-index: 1;
        width: 0;
        height: 0;
        border-width: 36px;
        border-color: #ffd000 #ffd000 transparent transparent;
        border-style: solid;
        border-top-right-radius: 3px;
      }
    }
  }

  .settings-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9999;
    height: 40px;
    display: flex;
    flex-direction: column;

    button {
      @include btn-reset();

      color: #1d202b;
      cursor: pointer;
      border-radius: 3px;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 5px;

      &:hover {
        background-color: #eff2f5;
      }
    }
  }

  .state-container {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 9999;
    height: 40px;
    display: flex;
    flex-direction: column;
    pointer-events: none;

    button {
      @include btn-reset();

      color: #1d202b;
      cursor: pointer;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 5px 10px;
      border-radius: 50px;
      background-color: rgba($color: #fff, $alpha: 0.75);

      span {
        .label {
          margin-left: 5px;
        }

        .saver {
          svg {
            @include keyframes(animate-preloader) {
              0% {
                transform: rotate(0deg);
              }

              100% {
                transform: rotate(360deg);
              }
            }

            animation: animate-preloader 3s linear infinite;
          }
        }

        .error {
          svg {
            @include keyframes(animate-error) {
              0% {
                transform: scale(1);
              }

              50% {
                transform: scale(1.15);
              }

              100% {
                transform: scale(1);
              }
            }

            animation: animate-error 0.5s linear infinite;
          }
        }
      }
    }
  }
}

.logo-container {
  display: flex;
  flex-wrap: wrap;

  .logo-wrap {
    padding: 0.2rem;
    height: 150px;

    &.selected {
      button {
        background-color: #cfcacd;
        border-color: #1d9bf1;
        border: 1px solid #1d9bf1;

        &:focus {
          background-color: #cfcacd;
          border: 1px solid #1d9bf1;
        }
      }
    }

    button {
      @include btn-reset();
      width: 100%;
      height: 100%;
      padding: 1rem;
      background-color: #dddddd;
      box-shadow: 0 0 5px 1px #c9c9c9;
      transition: all 200ms;

      &:focus {
        background-color: #959394;
      }
    }
  }
}

.media-library-modal,
.icon-selector-modal {
  .modal-dialog {
    margin: 0;
  }
}

.modal-dialog {
  margin: 3rem auto;

  .modal-header {
    background-color: rgba(30, 156, 241, 0.08);

    .modal-title {
      width: 100%;

      .modal-close-btn {
        @include btn-reset();
        width: 50px;
        height: 50px;
      }
    }
  }

  .modal-body {
    .circle-picker {
      & > span:not(:nth-child(1)) > div > span > div {
        background-color: rgba(30, 156, 241, 0.8) !important;
      }
    }
  }

  .modal-footer {
    .btn-outline {
      @include btn-reset();
      padding: 0.3rem 1rem;
      border-radius: 8px;
      min-width: 100px;
      border: 1px solid #d8d8d8;
      color: #959394;

      &:hover {
        background-color: #e9ecef;
      }
    }

    .btn-primary {
      @include btn-reset();
      padding: 0.3rem 1rem;
      border-radius: 8px;
      min-width: 100px;
      background-color: #20bf55;
      color: #fff;

      &:hover {
        background-color: #1ca74ad0;
      }
    }
  }
}

.settings-modal {
  &.background-modal,
  &.audio-modal {
    // .modal-dialog{
    //   max-width: 70%;
    //   margin: 3rem auto;

    .modal-header {
      background-color: rgba(30, 156, 241, 0.08);
    }

    .adv-option {
      .bg-adv-btn {
        cursor: pointer;
        font-weight: 400;
        font-size: 1.2rem;

        .label {
          text-decoration: underline;
          font-weight: 500;
        }
      }
    }

    .modal-footer {
      .btn-outline {
        @include btn-reset();
        padding: 0.3rem 1rem;
        border-radius: 8px;
        min-width: 100px;
        border: 1px solid #d8d8d8;
        color: #959394;

        &:hover {
          background-color: #e9ecef;
        }
      }

      .btn-primary {
        @include btn-reset();
        padding: 0.3rem 1rem;
        border-radius: 8px;
        min-width: 100px;
        background-color: #20bf55;
        color: #fff;

        &:hover {
          background-color: #1ca74ad0;
        }
      }
    }

    .background-images,
    .audio-select {
      .uyo-switch {
        h5 {
          font-weight: 400;
        }

        * {
          height: 100%;
        }

        .preset-btn {
          @include btn-reset();
          text-transform: none;
          min-width: 150px;
          padding: 0;
          color: #959394;
          border: 2px solid #d8d8d8;
          border-radius: 7px 0 0 7px;
          font-size: 0.8rem;
          border-right: 0;

          &:focus {
            border: 2px solid #1d9bf1;
          }

          &.active {
            color: white;
            background-color: #1d9bf1;
            border: 2px solid #1d9bf1;
          }
        }

        .new-btn {
          @include btn-reset();
          text-transform: none;
          min-width: 150px;
          padding: 0;
          color: #959394;
          border: 2px solid #d8d8d8;
          border-radius: 0 7px 7px 0;
          font-size: 0.8rem;
          border-left: 0;

          &:focus {
            border: 2px solid #1d9bf1;
          }

          &.active {
            color: white;
            background-color: #1d9bf1;
            border: 2px solid #1d9bf1;
          }
        }
      }

      .main-wrapper {
        border: 2px solid #d8d8d8;
        border-radius: 10px;
        padding: 0.5rem 1rem 1rem 1rem;
        min-height: 421px;

        @media (max-width: $bp-lg) {
          min-height: 320px;
        }

        @media (min-width: $bp-lg) {
          min-height: 340px;
        }

        @media (min-width: 1600px) {
          min-height: 370px;
        }

        @media (min-width: 1800px) {
          min-height: 420px;
        }

        &.upload-own,
        &.upload-audio {
          display: flex;
          justify-content: center;
          align-items: center;

          .img-selected-container,
          .audio-selected-container {
            width: 100%;

            audio {
              border-radius: 50px;
            }

            .property {
              font-weight: 500;
              font-size: 0.8rem;
              width: 20%;
              flex: 0 0 auto;
            }

            .value {
              font-size: 0.8rem;

              &.name {
                font-weight: 500;
                color: #1d9bf1;
              }
            }

            .btn-grp {
              .change-btn,
              .remove-btn {
                @include btn-reset();
                width: 100%;
                border-radius: 5px;
                color: #ffffff;
                font-size: 0.7rem;
                padding: 5px 0;
              }

              .change-btn {
                background-color: #1d9bf1;

                &:focus {
                  background-color: #1d9bf1;
                }
              }

              .remove-btn {
                background-color: #e2191f;

                &:focus {
                  background-color: #e2191f;
                }
              }
            }
          }

          .bi-uyo {
            .hint {
              margin-top: -4rem;
              margin-bottom: 1rem;
              font-weight: 400;
              color: #415262;
            }

            .upload-btn {
              background-color: #1d9bf1;
              border-color: #1d9bf1;

              &:hover {
                background-color: #1674b3;
              }
            }
          }
        }

        .pagination {
          .page-item {
            margin: 0 0.5rem;
            min-width: 100px;
            text-align: center;

            .page-link {
              border-radius: 5px;
              border: 2px solid #d8d8d8;
              color: #959394;
              padding: 5px 0;
              text-transform: uppercase;
              font-size: 0.7em;
            }

            &.active {
              .page-link {
                border: 2px solid #1d9bf1;
                background-color: #1d9bf1;
                color: #fff;
              }
            }
          }
        }

        .images-container {
          padding: 0.5rem 5px 0;
          display: flex;
          flex-wrap: wrap;
          height: 300px;
          overflow: auto;

          @media (max-width: $bp-lg) {
            height: 300px;
          }

          @media (min-width: $bp-lg) {
            height: 240px;
          }

          @media (min-width: 1600px) {
            height: 250px;
          }

          @media (min-width: 1800px) {
            height: 300px;
          }

          .image-item {
            .btn-container {
              display: flex;
              width: 100%;
              height: 100%;
              padding: 0.5rem;

              button {
                @include btn-reset();
                padding: 1rem;
                width: 100%;
                height: 100%;
                color: #959394;
                padding: 0;
                box-shadow: 0 0 5px 1px #c9c9c9;
                border: 1px solid #e2e2e2;
                transition: all 200ms;

                &:hover {
                  scale: 1.05;
                }
              }
            }

            &.selected {
              button {
                scale: 1.1;
                position: relative;
                z-index: 20;

                &,
                &:active,
                &:focus {
                  border: 3px solid #1d9bf1;
                }

                &:hover {
                  scale: 1.1;
                }
              }
            }
          }
        }

        .image-settings-container {
          .setting-title {
            font-size: 0.8rem;
            font-weight: 400;
            margin: 0.5rem 0 20px 0;
          }
        }

        .inner-wrapper {
          white-space: normal;
          height: 300px;

          .bg-wrap {
            margin-bottom: 1em;
          }
        }
      }
    }

    // }
  }

  .modal-dialog {
    @media (min-width: 680px) {
      max-width: 680px;
    }
  }

  .bg-settings-container {
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 40px;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
  }

  .slide-help-modal {
    p {
      line-height: 2;
    }

    img {
      max-height: 24px !important;
    }
  }

  .slide-notes-modal-content {
    .slide-notes-input {
      width: 100%;
      min-height: 250px;
      max-height: 550px;

      .MuiInputBase-root.MuiInput-root {
        height: 100%;

        &::before,
        &::after {
          border: 0 !important;
        }
      }
    }
  }

  .settings-wrap {
    margin-bottom: 20px;
  }

  &.slide-settings-modal {
    .slide-settings-base {
      .adv-slide-settings-sub-container,
      .adv-presentation-settings-sub-container {
        position: relative;

        .hint {
          position: absolute;
          width: 90%;
          text-align: center;
          height: 50%;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 15px auto;

          span {
            font-size: 16px;
            line-height: 1.4;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.adv-slide-settings-sub-container,
.adv-presentation-settings-sub-container {
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 5px;
  position: relative;
  padding-left: var(--bs-gutter-x, 0.75rem) !important;
  padding-right: var(--bs-gutter-x, 0.75rem) !important;

  &.img, &.audio{
    justify-content: space-between;
    padding-bottom: 40px !important;
  }

  &.thumbnail {
    justify-content: space-around;
  }

  .thumbnail-container {
    max-height: 125px;
    max-width: 220px;
    object-fit: cover;
    min-height: 120px;
    margin: 10px 0;
  }

  audio {
    border-radius: 50px;
  }

  .property {
    font-weight: 500;
    font-size: 0.8rem;
  }

  .value {
    font-size: 0.8rem;

    &.name {
      font-weight: 500;
      color: #1d9bf1;
    }
  }

  .hint {
    color: #c0c0c0;
    position: absolute;
    width: 90%;
    text-align: center;
    height: 50%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px auto;

    span{
      font-weight: 400;
    }
  }
}

.presentation-settings-modal {
  // .modal-dialog{
  // @media (max-width: $bp-lg) {
  //  max-width: 90%;
  // }

  // @media (min-width: $bp-lg) {
  //  max-width: 80%;
  // }

  // @media (min-width:$bp-xxl) {
  //   max-width: 70%;
  // }

  .core-lib-row {
    margin-top: 1.5rem;

    input {
      margin-top: 0;
    }
  }

  .slide-btn-row {
    input {
      margin-top: 0;
    }
  }

  .slide-num-row {
    input {
      width: 100%;
    }
  }

  .presentation-audio-selector {
    min-height: 250px;
    border: 2px solid #d8d8d8;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0.5rem;
    padding: 0 2rem;

    .hint {
      margin: -3rem 0 1rem 0;

      span {
        text-align: center;
        color: #d8d8d8;

        @media (min-width: $bp-lg) {
          font-size: 0.85rem;
        }

        @media (min-width: $bp-xxl) {
          font-size: 1rem;
        }
      }
    }

    .media-lib-btn {
      @include btn-reset();
      width: auto;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      background-color: #1d9bf1;

      &:hover {
        background-color: #1674b3;
      }

      &:focus {
        background-color: #1d9bf1;
      }
    }

    .property,
    .value {
      font-size: 0.8rem;

      &.name {
        font-weight: 500;
        color: #1d9bf1;
      }
    }

    .property {
      font-weight: 500;
    }

    audio {
      border-radius: 50px;
      width: 100%;
    }

    .btn-grp {
      margin-top: 1rem;

      button {
        @include btn-reset();
        min-width: 40px;
        color: white;
        font-size: 0.7rem;
        padding: 0.3rem 0.7rem;
        border-radius: 5px;
      }

      .change-btn {
        background-color: #1d9bf1;
      }

      .remove-btn {
        background-color: #e2191f;
      }
    }
  }

  .category-selector {
    border-bottom: 1px solid #cccccc;
    font-size: 0.7rem;

    & > div {
      border: none;
    }
  }

  // }
}

.layout-item {
  margin-bottom: 70px;
}

.login_modal {

  .modal-body {
    padding: 0 2rem;

    p {
      font-size: 0.8rem;
      line-height: 1.5;
    }
  }

  // .close-modal-btn {
  //   background-color: $white;
  //   position: absolute;
  //   width: 40px;
  //   height: 40px;
  //   top: -20px;
  //   right: -20px;
  //   padding: 0;
  //   border-radius: 50%;
  // }
}

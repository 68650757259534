// COURSES SINGLE

$checkbox-size: 1.5rem;
$checkbox-size-lg: 1rem;

.single {
  &.course {
    .article {

      .article__body,
      .section__wrapper {
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $border-colour;
      }

      // indent item & signoff
      .section__entity__unit,
      .signoff__block__unit {
        padding: 0 1.5rem;
      }

      // Checkbox style
      button {
        &.checkbox {
          @include btn-reset();
          display: flex;
          height: calc(1.5 * 1rem); // same line height as text

          svg {
            width: $checkbox-size;
            height: 100%;
            color: $normal-font-colour;

            @media (min-width: $bp-lg) {
              width: $checkbox-size-lg;
            }

            &.selected__icon {
              color: $green;
            }
          }
        }
      }

      // Course section
      .section__wrapper {
        padding-bottom: 1rem;

        .section__body__wrapper {
          margin-bottom: 1rem;
        }

        // Course item
        .section__entity__unit {
          margin-bottom: 0.5rem;

          .section__entity__title__wrapper {

            button {
              @include btn-reset();
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              color: $primary-colour;
              text-align: left;
  
              span {
                padding-right: 1rem;
              }

              .arrow__icon {
                min-width: calc(1.5 * 1rem);
                width: calc(1.5 * 1rem);
                height: calc(1.5 * 1rem); // same line height as text
                color: $accent-colour;
              }
            }

            &:hover {
              opacity: 0.8;
            }
          }
            
        }
          
      }

      // Sign off checkbox
      .signoff__block__unit {
        label {
          margin: 0;
        }
      }

      // Course submit
      .signoff__button__wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;

        svg {
          margin-left: 0.5rem;
        }
      }

      // Submitted confirmation
      .submitted__container {
        display: flex;
        align-items: flex-start;
        margin: 2rem 0;

        svg {
          min-width: calc(1.5 * 1rem);
          width: calc(1.5 * 1rem);
          height: calc(1.5 * 1rem); // same line height as text
          margin-right: 0.5rem;
        }
      }
    }
  }
}
// GALLERY MODAL & CAROUSEL

$modal-padding: 2rem;

.image-gallery-backdrop {
  &.show {
    opacity: 0.75; // overide default bootstrap modal backdrop
  }
}

.image-gallery-modal {
  width: 75%;
  max-width: 350px;
  min-height: calc(100% - 3.5rem);
  margin: 1.75rem auto;

  @media (min-width: $bp-lg) {
    max-width: calc(1600px + (#{$modal-padding} * 2));
  }

  .modal-content {
    border: initial;
    background-color: transparent;
  }

  .modal-body {
    padding: 0;
  }

  .carousel {
    padding-bottom: 60px; // for pagination

    .carousel-inner {
      border-radius: 0.3rem;
      overflow: hidden;
    }

    .carousel-item {
      background-color: #fff;

      .content {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;

        @media (min-width: $bp-lg) {
          flex-direction: row;
        }

        .content__text {
          flex: 1;
          display: flex;
          flex-direction: column;
          position: relative;
          padding: $modal-padding;

          .title {
            font-size: 1.25rem;

            @media (min-width: $bp-lg) {
              font-size: 2rem;
            }
          }

          .text-container {
            // Clamps between 150px - 400px
            // a minimum value, a preferred value, and a maximum allowed value 
            max-height: clamp(150px, 50%, 400px);
            overflow-y: auto;
            margin: auto 0;
          }

          .text {
            height: 100px;
            overflow-y: auto;

            @media (min-width: $bp-lg) {
              height: 150px;
            }

          }

          .author {
            display: flex;
            align-items: center;

            .author__image {
              width: 30px;
            }

            .author__name {
              margin-left: 0.25rem;
              font-size: 0.8rem;
              line-height: 1.2;
            }
          }
        }

        .content__footer {
          margin-top: 1rem;

          @media (min-width: $bp-lg) {
            margin-top: auto;
          }
        }

        .content__image {
          width: 100%;
          max-width: 800px;
          overflow: hidden;

          @media (min-width: $bp-lg) {
            width: 50%;
            border-top-right-radius: 0.3rem; // same as bootstrap
            border-bottom-right-radius: 0.3rem; // same as bootstrap
          }

        }
      }

    }

    .carousel-indicators {
      margin-bottom: 0;
  
      li {
        background-color: $primary-colour;
      }
    }
  
    .carousel-control-prev, 
    .carousel-control-next {
      width: 30px;
      height: 30px;
      top: auto;
      bottom: 0;
      margin: 0 auto;
      color: $primary-colour;
      z-index: 18;
      opacity: 1;
      background-color: #fff;
      border-radius: 50%;
      transition: color 500ms ease;

      &:hover {
        color: $secondary-colour;
        transition: color 500ms ease;
      }
    }

    .carousel-control-prev {
      right: 60px;
    }
    
    .carousel-control-next {
      left: 60px;
    }
  }

  .close-modal-btn {
    @include btn-reset();
    position: absolute;
    top: -20px;
    right: -20px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #fff;

    &:focus {
      outline: none;
    }

    svg {
      width: 100%;
      height: auto;
      display: block;
      color: $primary-colour;
      transition: color 500ms ease;
    }

    &:hover {

      svg {
        color: $secondary-colour;
        transition: color 500ms ease;
      }

    }
  }
  
}
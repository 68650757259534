.ce-paragraph {
  outline: none;
  cursor: text;
  padding: 0;
}

.ce-paragraph[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.ce-paragraph[data-placeholder]:empty::before {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}

.ce-paragraph p:first-of-type {
  margin-top: 0;
}

.ce-paragraph p:last-of-type {
  margin-bottom: 0;
}

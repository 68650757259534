// ACCOUNT

.account {
  .user-image {
    width: 100px;
    height: 100px;
    margin-top: 2rem;
    border: 1px solid $primary-colour;
    font-size: 70px;
    font-weight: 400;

    svg {
      width: 80px;
      height: auto;
    }

    @media (min-width: $bp-lg) {
      width: 150px;
      height: 150px;
      margin-top: 3rem;
    }
  }

  .greeting {
    margin-top: 1em;
  }

  .username {
    font-size: 1.125rem;

    @media (min-width: $bp-lg) {
      font-size: 1rem;
    }

    p {
      margin-bottom: 0;
    }
  }

  .role {
    p {
      margin-bottom: 0;
    }
  }

  .actions {
    .edit-profile-link {
      margin-top: 10px;
      color: $secondary-colour;

      &:hover {
        text-decoration: none;
      }

      .icon {
        margin-right: 5px;
      }
    }
  }

  .creators {
    margin-top: 4rem;
    text-align: center;
  }

  .add-in {
    margin-top: 4.5rem;

    .outlook-icon {
      width: 80px;
    }

    .get-add-in {
      padding: 6px 20px !important;
    }

    .add-in-container {
      img {
        // height: auto;
        // width: 100%;
      }
    }
  }

  

  .ca-logo-link {
    width: 350px;
  }
}

// LOADING SPINNER
.spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 2.5em;
  // background-color: $gray-900;

  &.sidebar-modal-spinner {
    margin-top: 0;
  }

  /* ========================================
    App.js spinner
  ======================================== */
  &.spinner-container--app {
    width: 100%;
    min-height: 100vh;
    align-items: center;
  }

  /* ========================================
    Screen spinner (e.g - news.js)
  ======================================== */
  &.spinner-container--screen {
    min-height: 100vh;
    position: absolute;
    top: -$header-height-xl;
    left: 0;
    right: 0;
    align-items: center;

    @media (min-width: $bp-lg) {
      top: -$header-height-xl;
    }

    @media (min-width: $bp-xl) {
      top: -$header-height-xl;
    }
  }

  /* ========================================
    Conversation.js (messages)
  ======================================== */
  &.spinner-container--conversation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    left: 0;
    right: 0;
  }
}


/* ========================================
  Screen spinner - nav expanded
======================================== */
.nav-expanded {

  /* Screen spinner (e.g - news.js)
  ======================================== */
  .spinner-container--screen {
    left: navbar-width($bp-lg);

    @media (min-width: $bp-xl) {
      left: navbar-width($bp-xl);
    }
  
    @media (min-width: $bp-xxl) {
      left: navbar-width($bp-xxl);
    }
  }

}



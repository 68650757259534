.ce-block.ancillary, .ancillary-container.ancillary_block {
  width: 100%;

  .cdx-ancillary-container, .ancillary-content-container {
    background: #edf8fe;
    border-left: 3px solid #1d9bf1;
    padding: 10px 20px;
    margin-bottom: 15px;
  }

  .cdx-ancillary-content-container, .ancillary-content-container{
    display: flex;
    flex-wrap: wrap;
  }

  .cdx-ancillary-input, .ancillary-content {
    width: 100%;
    display: flex;

    &.cdx-ancillary-input:empty:before{
      content: attr(data-placeholder);
      pointer-events: none;
      display: block; /* For Firefox */
      color: #707684;
    }

    &.cdx-ancillary-input::after{
      display: none;
    }

    &.ancillary-heading{
      font-weight: 400 !important;
      font-size: 18px;
    }
    &.ancillary-body{
      font-weight: 300 !important;
      font-size: 14px;
    }
    &:focus-visible {
      outline: none;
    }
  }
}

.ce-block.pillar {
  .ce-block__content {
    .cdx-media-container {
      .replace-btn {
        position: absolute;
        z-index: 20;
        right: 10px;
        top: 58px;
      }
      .cdx-text-container {
        .cdx-header-container {
          .cdx-header-redactor {
            position: relative;
            padding-right: 35px;

            .cdx-mode-toggle {
              top: 0;
              right: -35px;
              position: absolute;
            }
          }

          .cdx-accord-toggle {
            position: absolute;
            bottom: 0;
            right: -10px;
            background: transparent;
            border: none;
            display: flex;
            padding: 0;
            transition: all 0.5s ease-in-out;

            svg {
              width: 50px;
              height: 50px;
              fill: #fff;
              padding: 0;
            }
          }
        }

        .cdx-heading-input,
        .cdx-sub-heading-input,
        .cdx-body-input {
          &:hover {
            box-shadow: 0 0 5px #1d9bf1;
          }

          &[data-placeholder]:empty::before {
            content: attr(data-placeholder);
            font-weight: inherit;
            font-size: inherit;
            line-height: inherit;
            color: #707684;
          }
        }

        &.active {
          .cdx-accord-toggle {
            transform: rotate(-180deg);
            transition: all 0.5s ease-in-out;
          }
        }

        .cdx-color-toggle {
          right: -20px;
        }

        .colors-container {
          position: absolute;
          top: -40px;
          .color-btn {
            width: 30px;
            height: 30px;
            border: 2px solid lightgray;
            margin-right: 10px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  [content-editable]{
    outline: 0px solid transparent;
  }
}

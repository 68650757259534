$width: 1366px;
$height: 768px;

.view-presentation {
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .slide-wrap {
    .slide {
  
      .codex-editor {
        width: $width;
        height: $height;
        max-width: $width;
        max-height: $height;
      }

      .table {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        font-size: 14px;
        border-top: 1px solid black;
        line-height: 1.4;
        
        .table-row {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
          position: relative;
          border-bottom: 1px solid black;

        }
      }
      
      .video_block{
        video{
          width: 100%;
        }
      }

      span {
        &.XL{
          font-size: 32px !important;
        }

        &.L{
          font-size: 28px !important;
        }

        &.S{
          font-size: 20px !important;
        }

        &.XS{
          font-size: 16px !important;
        }
      }
    }
  }

  .ps-viewer {
    .reveal {
      .slide-number {
        top: 10px;
        bottom: auto;
      }
    }
  }
}
// PODCAST SINGLE

.single {
  &.podcast {
    .article {
      .article__image {
        margin-bottom: 0;
      }

      .article__audio {
        margin-bottom: 2em;

        audio {
          width: 100%;
        }
      }
    }
  }
}

.cdx-nested-list__item [contenteditable] {
  outline: none;
}

.cdx-nested-list__settings {
  display: flex;
}

.cdx-nested-list__settings .cdx-settings-button {
  width: 50%;
}

.player-container {
  &.slide-audio{
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1200 !important;
    transition: 0.5s ease;
    max-height: 0;
    overflow: hidden;
  }

  &.visible {
    transition: 0.5s ease;
    max-height: 100%;
  }

  .presentation-player {
    .player-button {
      @include btn-reset();
      position: relative;
      z-index: 1054;
      color: #777777;
      transition: color 0.5s;

      &:hover {
        color: #0d6efd;
        transition: color 0.5s;
      }
    }

    .spinner {
      content: " ";
      display: block;
      visibility: hidden;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      border: 3px solid #0d6efd;
      border-color: #0d6efd transparent #0d6efd transparent;
      animation: spinner 1.2s linear infinite;

      &.playing {
        visibility: visible !important;
      }
    }

    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  .prompt {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    color: #444;
    padding: 0 10px;
    border-radius: 10px;
  }

  .audio-player {
    border-radius: 50px;
    height: 32px;
    background-color: #222222;
    border: 1px solid #444;

    .control-slider {
      margin-left: 10px;
      margin-right: 10px;
    }

    .player-section {
      &,
      .row,
      .col {
        height: 100%;
      }

      .row,
      .col {
        padding: 0;
        margin: 0;
      }

      &.player {
        width: 95%;
        flex: unset;
      }


      .part {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0;
        &.btn {
          min-width: 30px;

          .control-btn {
            padding: 0 2px;
            width: 28px;
            height: 28px;

            svg {
              fill: #bababa;
              height: 20px;
            }
            &:hover {
              svg {
                fill: #fff;
              }
            }
          }
        }

        &.track,
        &.volume {
          .control-slider {
            color: #bababa;
            .MuiSlider-track,
            .MuiSlider-thumb {
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  .control-btn-container {
    margin-top: 10px;

    .control-btn {
      background-color: #edf7fe;
      border: 1px solid #dae0e7;
      border-radius: 5px;
      color: #16324f !important;
      width: 32px;
      height: 32px;
      margin-right: 10px;

      svg {
        stroke: #16324f;
        fill: #16324f;
      }

      &:hover {
        background-color: #1d9bf1;
        border-color: #1d9bf1;
        color: #ffffff !important;
        stroke: #fff;
        fill: #fff;
      }

      &.remove-btn {
        padding: 0;
      }

      &.remove-btn:hover {
        background-color: #bf1e2c;
        border-color: #bf1e2c;

        svg {
          &,
          * {
            color: #fff;
            stroke: #fff;
            fill: #fff;
          }
        }
      }
    }

    .auto-btn {
      &.on {
        svg {
          fill: #fff;
        }
      }
    }
  }
}

.prompt-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;

  .prompt {
    width: 300px;
    height: fit-content;
    text-align: center;
    background: #222;
    border-radius: 11px;
    padding: 5px 5px;
    color: #fff;
    border: 1px solid #444;
    z-index: 2;
  }
}

.add-library-container {
  .page-header {
    h1 {
      font-size: 1.25rem;
    }

    .title-container {
      border-bottom: 1px solid #d2d0cf;
    }

    #title-label {
      &[data-shrink="false"] {
        color: rgba(0, 0, 0, 0.87);
      }

      &[data-shrink="true"] {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .presentation-info {
    color: "primary";
  }

  .filter-container {
    border-radius: 4px;
    padding: 15px 0;
  }

  @media (min-width: $bp-xxl) {
    .col-xxl-1-2 {
      flex: 0 0 auto;
      width: 10%;
    }
  }

  @media (min-width: $bp-xl) and (max-width: $bp-xxl) {
    .col-xl-1-2 {
      flex: 0 0 auto;
      width: 12.5%;
    }
  }

  .thumb-title {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .pres-view-item {
    .MuiTypography-root.MuiTypography-subtitle {
      font-size: 0.8em;
    }
  }

  @media (max-width: $bp-lg) {
    .pres-view-item {
      .MuiTypography-root.MuiTypography-subtitle {
        font-size: 16px;
      }
    }
  }
}

// GALLERIES SINGLE

$gallery-item-spacing: 0.125rem;

.single {
  &.galleries {
    .gallery {
      .row {
        margin-left: $gallery-item-spacing;
        margin-right: $gallery-item-spacing;

        .gallery-item {
          padding: $gallery-item-spacing;

          .gallery-item__image {
            opacity: 1;
            cursor: pointer;
            transition: opacity 500ms ease;

            &:hover {
              opacity: 0.8;
              transition: opacity 500ms ease;
            }
          }
        }
      }
    }
  }
}

// ERROR COMPONENT

.error-container {
  display: flex;
  justify-content: center;
  margin-top: 10vh;

  .error {
    text-align: center;

    .app-icon {
      width: 100px;
      margin: 0 auto 1rem auto;
    }

    .error__status {
      span {
        font-weight: 400;
      }
    }

    .error__message {
      margin-bottom: 0;
    }
  }
}
// CONTACTS ITEM

$user-image-size: 76px;

.contacts {

  .contacts-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    padding-bottom: 5px;
    // Flick height = 75%;
    @include border-flick();

    .contacts-item__image {
      border: initial;

      .user-image {
        width: $user-image-size;
        height: $user-image-size;
        transform: translateZ(0); // Safari border-radius fix

        svg {
          width: $user-image-size / 2;
          height: $user-image-size / 2;
          color: $light-font-colour;
          transform: scale(1);
          transition: transform 500ms ease-in-out;
          will-change: transform;
        }
      }
    }

    .contacts-item__content {
      margin-bottom: 0.5rem;
      padding: 0 15px;

      .title {
        @include line-clamp(1);
        margin-bottom: 0;
      }

      .contacts-item__role {
        margin-bottom: 0;
        font-size: 0.7rem;
        font-weight: 300;
      }
    }

    .contacts-item__footer {
      margin-top: auto;
      padding-left: 15px;
      padding-bottom: initial;
      padding-top: initial;
      border-bottom: initial;
    }

    .contacts-item__btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 0.75rem;
      text-decoration: none;
      @include transformY-onHover();

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background-color: transparent;
        border: initial;

        svg {
          width: 1rem;
          height: auto;
        }
      }

      span {
        display: block;
        margin-top: 5px;
        font-size: 0.6rem;
        line-height: 1;
      }

      &.contacts-item__btn--email {
        svg,
        span {
          color: $primary-colour;
        }
      }

      &.contacts-item__btn--phone {
        svg, 
        span {
          color: $secondary-colour;
        }
      }

      &.contacts-item__btn--sms {
        svg,
        span {
          color: $gradient-pink;
        }
      }

      &.contacts-item__btn--message {
        svg, 
        span {
          color: $gradient-orange;
        }
      }
    }

    // HOVERED STATE
    &.hovered {
      .contacts-item__image {
        .user-image {
          svg {
            transform: scale(1.1);
            transition: transform 500ms ease-in-out;
            will-change: transform;
          }
        }
      }
    }

  }

}

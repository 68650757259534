// COURSE ITEM

.courses {
  .page-header{
    .category-selector{
      &>div:nth-of-type(2){
        min-width: 250px;
      }
    }
  }
  .course-item {

    .submitted-flag {
      font-size: 0.8rem;
      color: $green;

      span {
        margin-left: 0.5rem;
      }
    }
    .course-item__content{
      padding-left: 0;

      @media (max-width: $bp-sm) {
        padding-left: 15px;
      }

      @media (min-width: $bp-sm) {
        margin: .75rem 0;
      }
    }
    .category__text{
      background-color: #e6e6e6;
      border-radius: 5px;
      padding: 0 5px;
      color: #333;

      @media (max-width: $bp-lg) {
        font-size: 0.8rem !important;
      }

      @media (min-width: $bp-lg) {
        font-size: 0.65rem !important;
      }
    }

    @media (max-width: $bp-lg) {
      margin-bottom: 30px;
    }

    @media (min-width: $bp-lg) {
      margin-bottom: 50px;
    }
  }
}

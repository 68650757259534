// COMMENTS

$user-image-size: 40px;

.comments {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid $border-colour;

  .comments__header {
    margin-bottom: 1rem;

    svg {
      margin-right: 0.5rem;
    }

  }

  .comments__content {
    margin-top: 2rem;
  }

  .comment {
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid $border-colour;
    background-color: $light-grey;

    .comment__image {
      width: $user-image-size;
      height: $user-image-size;
      margin-right: 0.5rem;
    }

    .comment__user {
      font-weight: 400;
      color: $secondary-colour;
    }

    .comment__header {
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $border-colour;

      .timestamp {
        color: $normal-font-colour;
  
        .timestamp__icon {
          width: 15px;
          height: 100%;
        }
  
        .timestamp__text {
          margin-left: 0.25em;
          font-size: 0.8rem;
        }
  
      }
    }

  }

}
// EVENTS

$header-font-size: 1.4rem;
$block-spacing: 30px; // Same event item

.events {
  // TODAY DATE HEADING
  .events__today {
    display: flex;
    align-items: center;
    margin-bottom: $page-margin;

    svg {
      margin-right: 0.5rem;
      color: $gradient-orange;
    }

    h5 {
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  // YEAR BLOCK
  .year {
    .year__header {
      position: sticky;
      top: 52px;
      margin-bottom: $block-spacing * 1.5;
      margin-left: -15px; // offset
      margin-right: -15px; // offset
      padding: 0.5rem 0.5rem 0.5rem 30px;
      background-color: #fff;
      z-index: 1;

      @media (min-width: $bp-lg) {
        margin-left: -30px; // offset
        margin-right: -30px; // offset
      }

      h4 {
        margin-bottom: 0;
        font-size: $header-font-size;
        color: $primary-colour;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($primary-colour, 0.05);
      }
    }
  }

  // MONTH BLOCK HEADING
  .month {
    margin-bottom: $block-spacing;

    .month__header {
      margin-bottom: 1rem;
      padding-bottom: 0.25rem;
      border-bottom: 1px solid $border-colour;
      color: $primary-colour;

      h4 {
        margin-left: 0.5rem;
        margin-bottom: 0;
        font-size: $header-font-size;
        text-transform: uppercase;
      }
    }
  }
}

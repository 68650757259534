/* ========================================
   DEFAULTS
======================================== */
#root {
  min-height: 100vh; // To allow full height on pages
  display: flex; // To allow full height on pages
  position: relative;
}

body {
  overflow-x: hidden;

  // &.navbar-expanded {
  //   // position: fixed; // prevent scrolling in background
  //     // => Causes issues (nav collapsing when nav opened after page scrolled) on chrome and safari iOS 14.1 
  //     // => Solves issues (nav collapsing) on safari iOS 15.1. But causes black square when address bar is minimised
  //   overflow: hidden; // prevent scrolling in background
  // }
}

.reveal [hidden] {
  display: inherit !important;
}

img {
  width: 100%;
  height: auto;
}

a {
  color: $link-colour;

  &:hover {
    color: $primary-colour;
  }
}

/* make <pre> wrap */
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

/* ========================================
   HELPER CLASSES
======================================== */

// PAGE CONTAINER
.page-container {
  width: 100%;
  margin-top: $header-height-xl;
  margin-left: 0;
  transition: margin-left 500ms ease;

  @media (min-width: $bp-lg) {
    margin-top: $header-height-xl;

    &.nav-expanded {
      margin-left: navbar-width($bp-lg);
      transition: margin-left 500ms ease;
    }
  }

  @media (min-width: $bp-xl) {
    margin-top: $header-height-xl;

    &.nav-expanded {
      margin-left: navbar-width($bp-xl);
    }
  }

  @media (min-width: $bp-xxl) {
    &.nav-expanded {
      margin-left: navbar-width($bp-xxl);
    }
  }

  // @1484px: expanded nav .content-container
  @media (min-width: ($navbar-width-xxl + $content-container-size)) {
    &.nav-expanded {
      .content-container {
        margin-left: calc((100% - #{$content-container-size}) * 0.3); // 30% of remaining space
        transition: margin-left 500ms ease;
      }
    }
  }
}

.content-container {
  height: 100%; // To allow full height on pages

  @media (min-width: $bp-sm) and (max-width: $bp-lg) {
    max-width: 95%;
  }

  @media (min-width: $bp-lg) {
    max-width: $content-container-size;
    padding: 0 30px;
  }

  // @1484px: collapsed nav .content-container
  @media (min-width: ($navbar-width-xxl + $content-container-size)) {
    margin-left: calc((100% - #{$content-container-size}) / 2);
    transition: margin-left 500ms ease;
  }
}

/* Icons
======================================== */
.favourite {
  color: $secondary-colour;
}

/* Gutters 
======================================== */
.gutter-lg {
  $gutter-size: 25px;

  @media (min-width: $bp-lg) {
    &:nth-of-type(odd) {
      padding-right: $gutter-size;
    }

    &:nth-of-type(even) {
      padding-left: $gutter-size;
    }
  }
}

[data-page="maps"] {
  width: 100% !important;
  max-width: 100% !important;
  height: calc(100vh - $header-height-xl);
  padding: 0;

  iframe {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
  }
}

.ps-relative {
  position: relative;
}
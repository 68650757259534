.cdx-contact-container {
  position: relative;
  display: inline-block;
  min-width: 50px;
}

.cdx-contact .cdx-loader {
  min-height: 200px;
}

.cdx-contact-settings-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.cdx-contact-btn,
.cdx-contact-replace-btn {
  color: #4f4f4f;
  user-select: none;
  background: #f5f5f5;
  border: none;
  outline: none;;
  border-radius: 10px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  margin: 0 auto;
}

.cdx-contact-btn,
.cdx-contact-replace-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.5;
  color: #4f4f4f;
  user-select: none;
  background: #f5f5f5;
  border: none;
  outline: none;;
  border-radius: 10px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
}

.cdx-contact-replace-btn:hover {
  opacity: 1;
}

.cdx-contact-btn svg,
.cdx-contact-replace-btn svg {
  margin-right: 5px;
}

.cdx-contact-input {
  padding: 0;
  margin: 0;
  outline: none;
  cursor: text;
}

.cdx-contact-input[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.cdx-contact-input[data-placeholder]:empty::before {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}
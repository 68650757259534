.pres-view-item {
  @include transition(all .25s ease-in-out);

  .mui-card {
    .content {
      padding: 14px 0 0 0 !important;
    }

    box-shadow: none;
  }

  .media {
    position: relative;
  }

  .play-container,
  .select-container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    z-index: 2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-top: 20px;

    // @include transition(all .25s ease-in-out);

    &.select-container {
      background-color: transparent;
    }

    &.selected {
      opacity: 1;
      @include transition(all .5s ease-in-out);
    }

    .selected-icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 24px;

      .icon,
      .tri {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 24px;
        height: 24px;
      }

      .icon {
        z-index: 2;
        svg{
          fill: #ffffff;
        }
      }

      .tri {
        z-index: 1;
        width: 0;
        height: 0;
        border-width: 20px;
        border-color: #4caf50 #4caf50 transparent transparent;
        border-style: solid;
        border-top-right-radius: 3px;
      }
    }
  }

  .category {
    position: absolute;
    top: auto;
    bottom: 10px;
    right: 10px;
    width: auto;
    height: 20px;
    text-align: right;
    background: rgba(0, 0, 0, 0.25);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    font-size: 1.25rem;
    line-height: 1.3;
    overflow: hidden;

    span {
      font-size: 0.75rem;
      color: #fff;
    }
  }

  .title {
    color: $dark-font-colour;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-size: 0.85rem;
    line-height: 1.6;
    overflow: hidden;
  }
}
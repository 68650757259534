.notifications.screen {
  width: 80%;
  margin: 0 auto;
  .notifications-list {
    margin-top: 30px;

    .notification-item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      box-shadow: 2px 3px 5px rgba(4, 4, 4, 0.2);
      margin-bottom: 15px;
      align-items: center;
      padding: 2px 10px;
      border-radius: 5px;
      transition: 0.25s all ease-in-out;

      .notification-item-label {
        flex-grow: 1;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 70%;
        display: inline-block;
        text-align: left;
      }

      .notification-item-icon{
        width: 30px;
        height: auto;
        &.unread{
          * {
            fill: #1d9bf1;
          }
        }
      }

      &:hover {
        transform: scale(1.02);
        transition: 0.25s all ease-in-out;
        cursor: pointer;
        background-color: #d4ecff;
      }
    }
  }
}

.ca-accordion {
  box-shadow: none !important;
  &::before {
    display: none;
  }

  .ca-accordion-summary {
    padding: 0;
    flex-direction: row-reverse;
    & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      transform: rotate(90deg);
    }
  }

  &.edit-profile {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &.Mui-expanded {
      margin-left: -15px !important;
      margin-right: -15px !important;
      margin-bottom: 15px;
    }

    .ca-accordion-summary {
      font-size: 18px;
      padding-right: 15px;
    }
  }

  .ca-accordion-details {
    margin-bottom: 15px;
  }
}

.cdx-quote-icon svg {
  transform: rotate(180deg);
}

.cdx-quote {
  margin: 0;
}

.cdx-quote__caption {
}

.quote-container [contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  left: 0;
  right: 0;
}

.quote-container [contentEditable="true"][data-placeholder]:empty::before {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}

.ce-block.quote {
  .quote-container {
    blockquote {
      &.quote-custom {
        &::before,
        &::after {
          color: inherit !important;
        }
      }
    }
  }
}

.content-wrapper{
  .quote-container.content_block{
    .quote-container{
      blockquote{
        &::before, &::after{
          color: inherit !important;
        }
      }
    }
  }
}

.cdx-quote-settings {
  display: flex;
}

.cdx-quote-settings .cdx-settings-button {
  width: 50%;
}

.cdx-quote-input {
  padding: 0;
  margin: 0;
  outline: none;
  cursor: text;
  font-family: inherit !important;
  font-weight: inherit;
  font-size: inherit;
}

/** placeholder on statistic needs be a lot smaller */
/* [data-slide-layout="statistic"] .quote-container [contentEditable=true][data-placeholder]::before {
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
} */

// NOTICES
$icon-size: 24px;

.notices {

  /* Notice item
  ======================================== */
  .notice-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding-bottom: 10px;
    // Flick height = 75%;
    @include border-flick(auto) {
      bottom: 10px;
    }
    
    a {
      &:hover {
        text-decoration: none;
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }

    .notice-item__icon {
      width: $icon-size;
      display: flex;
      color: $primary-colour;
    }

    .notice-item__group-container {
      width: 100%;
      display: block;
      padding: 0 3px 0 15px;
      text-align: inherit;

      .group {
        width: 100%;
        display: flex;
        margin: 0;
        font-weight: 400;
        font-size: 0.75rem;
        color: $primary-colour;
      }

      .timeago {
        display: flex;
        align-items: center;
        margin-left: auto;
        font-weight: 300;
        font-size: 0.75rem;
        color: $normal-font-colour;

        .timeago-label {
          margin-right: 3px;
        }
      }
    }

    button {
      &.add-topic {
        margin: 0 1rem;
        font-size: 0.8rem;
        color: $primary-colour;

        .add-topic__icon {
          width: $icon-size;
          display: flex;
          margin-right: 0.25rem;
        }
      }
    }

    /* Content
    ======================================== */
    .notice-item__content {
      padding-left: 0;
    }

    /* Author
    ======================================== */
    .notice-item__footer {
      margin-top: auto;
      padding-top: 1rem;
      border: none;

      .author {
        display: flex;
        align-items: center;

        .author__image {
          width: 30px;
          height: 30px;
        }

        .author__name {
          margin-left: 5px;
          font-size: 0.8rem;

          a {
            color: $primary-colour;
            @include transition-colour();

            &:hover {
              color: $secondary-colour;
              @include transition-colour();
            }
          }
        }
      }

      .edit-notice-btn {
        color: $primary-colour;
        @include transition-colour();

        @media (min-width: $bp-lg) {
          font-size: 0.8rem;
        }

        span {
          margin-left: 0.25rem;
        }

        &:hover {
          color: $secondary-colour;
          @include transition-colour();
        }
      }
    }

    &.no-image {
      .galleries-item__image {
        border: initial;
        background-color: rgba($primary-colour, 0.4);
      }
    }
  }
}

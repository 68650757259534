// Default layout for:
  // Courses
  // Quizzes
  // Noticeboard
  // Forms 
  // Polls
  // Useful links

.item {
  &.default-layout {
    display: flex;
    flex-direction: column;
    min-height: 170px;

    @media (min-width: $bp-lg) {
      min-height: 185px;
    }

    .item__footer {
      // Flick height = 75%;
      @include border-flick();
    }

    // NO IMAGE
    &.no-image {
      .item__content {
        padding-left: 0;
      }
    }
  }
}

// MESSAGE ITEM
.messages {

  .message-item {
    display: block;
    margin-bottom: 0;
    padding: 0.5rem;
    border-bottom: 1px solid $border-colour;
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    .message-item__image-wrapper {
      position: relative;

      .user-image {
        width: 60px;
        height: 60px;
      }
    }

    .message-item__unread-counter {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      right: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      border-radius: 50%;
      font-size: 0.5em;
      font-weight: 500;
      color: $pdfIconColour;
      background-color: $new-content-indicator-bg-colour;
    }

    .message-item__content {
      padding-left: 0;

      .title {
        margin: 0;
        @include line-clamp(1);
      }
  
      .message-item__timestamp {
        font-size: 0.7rem;
        color: $light-font-colour;
      }
  
      .excerpt {
        min-height: calc((0.8rem * 1.4) * 2);
        font-size: 0.8rem;
        line-height: 1.4;
        @include line-clamp(2);
      }
    }

    /* ACTIVE STATE
    ======================================== */
    &.active {
      background-color: rgba($primary-colour, 0.1);

      .message-item__content {
        .title {
          color: $primary-colour;
        }
      }
  
    }
  }

}
.search-modal{
    border-radius: 25px;
    border: none;

}

.search-modal{
    .modal-content{
        border: none;
        .modal-header{
            border: none;
            .search-container{
              width: 100%;
              .search{
                display: flex;
                border-radius: 5px;
                font-size: 0.8rem;
                color: $normal-font-colour;
                background: #fff;
                border: 1px solid $primary-colour;
                transition: width 500ms ease;

                input{
                    border: none;
                    border-radius: 5px;
                    outline: none;
                }

                button {
                  @include btn-reset();
                  padding: 0.25em 0.5em;
                  display: flex;
                  align-items: center;
          
                  svg {
                    width: 0.8rem;
                    height: auto;
                    color: $primary-colour;
                    transition: color 250ms ease;
                  }
          
                  &:hover {
                    svg {
                      color: $accent-colour;
                      transition: color 250ms ease;
                    }
                  }
                }

                &:focus-within {
                  width: 100%;
                  border: 1px solid $primary-colour;
                  transition: width 500ms ease;
                }
                input{
                  width: 100%;
                }
              }
            }
        }
    }
  }
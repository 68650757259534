// All typography elements (fonts imported @ _fonts.scss)
html {
  font-size: 16px;

  @media (min-width: $bp-lg) {
    font-size: 18px;
  }

  @media (min-width: $bp-xl) {
    font-size: 20px;
  }
}

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  // font-weight: 400;
  color: $dark-font-colour;
}

p {
  line-height: 1.75;
}
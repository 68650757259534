.node_reactions,
.comment_reactions {
  .btn {
    background-color: transparent;
    margin: 0 10px;
    border: none;
    padding: 10px;
    outline: none;

    &:active,
    &:focus,
    &:hover {
      outline: none !important;
      border: none !important;
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }

    &:hover {
      svg,
      .node_reactions_counter,
      .comment_reactions_counter {
        opacity: 1;
      }
    }

    .node_reactions_counter,
    .comment_reactions_counter {
      margin-left: 5px;
    }

    svg,
    .node_reactions_counter,
    .comment_reactions_counter {
      opacity: 0.5;
    }

    &.like {
      svg,
      .node_reactions_counter,
      .comment_reactions_counter {
        color: $reactionLike;
      }

      &.reacted {
        svg,
        .node_reactions_counter {
          opacity: 1;
        }
      }
    }

    &.love {
      svg,
      .node_reactions_counter {
        color: $reactionHeart;
      }

      &.reacted {
        svg,
        .node_reactions_counter {
          opacity: 1;
        }
      }
    }

    &.surprised {
      svg,
      .node_reactions_counter {
        color: $reactionSurprised;
      }
      
      &.reacted {
        svg,
        .node_reactions_counter {
          opacity: 1;
        }
      }
    }
  }
}

.comment_reactions {
  justify-content: flex-end;
  flex-direction: row;
  display: flex;

  .btn {
    margin: 0;
    padding-right: 0;
  }

  /* Spinner
  ======================================== */
  .spinner-border {
    width: 1rem;
    height: 1rem;
    border-width: 2px;
  }
}
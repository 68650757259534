.circular-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 99;

  &.fs {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #ffffff, $alpha: 0.75);

    &.t {
      background-color: rgba($color: #ffffff, $alpha: 0);
    }
  }
}

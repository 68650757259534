.cdx-client-container {
  position: relative;
  display: inline-block;
  min-width: 50px;
}

.cdx-client .cdx-loader {
  min-height: 200px;
}

.cdx-client-settings-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.cdx-client img {
  max-height: 100%;
}

.cdx-client p {
  outline: none;
  cursor: text;
  text-align: center;
  width: 100%;
  max-height: 150px;
  overflow: scroll;
  margin: 0;
  padding: 0;
}

.cdx-client p[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.cdx-client p[data-placeholder]:empty::before {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}


/**
 * Card flip
 */
.cdx-client {
  position: relative;
  display: block;
  height: 200px;
}

.cdx-client .front,
.cdx-client .back {
  width: 100%;
  height: 100%;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 0.5s;
  transition-property: transform, opacity;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.cdx-client .front {
  background-color: #fff;
  transform: rotateX(0deg);
  opacity: 1;
}

.cdx-client .back {
  background-color: #f9f9f9;
  transform: rotateX(-180deg);
  padding: 25px;
  opacity: 0;
  z-index: -1;
}

.cdx-client.flipped .front {
  transform: rotateX(180deg);
  opacity: 0;
}

.cdx-client.flipped .back {
  transform: rotateX(0deg);
  opacity: 1;
  z-index: 1;
}
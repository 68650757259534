/* Default Print Stylesheet Template
   by Rob Glazebrook of CSSnewbie.com
   Last Updated: June 4, 2008

   Feel free (nay, compelled) to edit, append, and
   manipulate this file as you see fit. */

@media print {
	html:not(.print-pdf) {

		background: #fff;
		width: auto;
		height: auto;
		overflow: visible;

		body {
			background: #fff;
			font-size: 20pt;
			width: auto;
			height: auto;
			border: 0;
			margin: 0 5%;
			padding: 0;
			overflow: visible;
			float: none !important;
		}

		.nestedarrow,
		.controls,
		.fork-reveal,
		.share-reveal,
		.state-background,
		.reveal .progress,
		.reveal .backgrounds,
		.reveal .slide-number {
			display: none !important;
		}

		body, p, td, li {
			font-size: 20pt!important;
			color: #000;
		}

		h1,h2,h3,h4,h5,h6 {
			color: #000!important;
			height: auto;
			line-height: normal;
			text-align: left;
			letter-spacing: normal;
		}

		/* Need to reduce the size of the fonts for printing */
		h1 { font-size: 28pt !important; }
		h2 { font-size: 24pt !important; }
		h3 { font-size: 22pt !important; }
		h4 { font-size: 22pt !important; font-variant: small-caps; }
		h5 { font-size: 21pt !important; }
		h6 { font-size: 20pt !important; font-style: italic; }

		a:link,
		a:visited {
			color: #000 !important;
			font-weight: bold;
			text-decoration: underline;
		}

		ul, ol, div, p {
			visibility: visible;
			position: static;
			width: auto;
			height: auto;
			display: block;
			overflow: visible;
			margin: 0;
			text-align: left !important;
		}
		.reveal pre,
		.reveal table {
			margin-left: 0;
			margin-right: 0;
		}
		.reveal pre code {
			padding: 20px;
		}
		.reveal blockquote {
			margin: 20px 0;
		}
		.reveal .slides {
			position: static !important;
			width: auto !important;
			height: auto !important;

			left: 0 !important;
			top: 0 !important;
			margin-left: 0 !important;
			margin-top: 0 !important;
			padding: 0 !important;
			zoom: 1 !important;
			transform: none !important;

			overflow: visible !important;
			display: block !important;

			text-align: left !important;
			perspective: none;

			perspective-origin: 50% 50%;
		}
		.reveal .slides section {
			visibility: visible !important;
			position: static !important;
			width: auto !important;
			height: auto !important;
			display: block !important;
			overflow: visible !important;

			left: 0 !important;
			top: 0 !important;
			margin-left: 0 !important;
			margin-top: 0 !important;
			padding: 60px 20px !important;
			z-index: auto !important;

			opacity: 1 !important;

			page-break-after: always !important;

			transform-style: flat !important;
			transform: none !important;
			transition: none !important;
		}
		.reveal .slides section.stack {
			padding: 0 !important;
		}
		.reveal section:last-of-type {
			page-break-after: avoid !important;
		}
		.reveal section .fragment {
			opacity: 1 !important;
			visibility: visible !important;

			transform: none !important;
		}
		.reveal section img {
			display: block;
			margin: 15px 0px;
			background: rgba(255,255,255,1);
			border: 1px solid #666;
			box-shadow: none;
		}

		.reveal section small {
			font-size: 0.8em;
		}

		.reveal .hljs {
			max-height: 100%;
			white-space: pre-wrap;
			word-wrap: break-word;
			word-break: break-word;
			font-size: 15pt;
		}

		.reveal .hljs .hljs-ln-numbers {
			white-space: nowrap;
		}

		.reveal .hljs td {
			font-size: inherit !important;
			color: inherit !important;
		}
	}
}

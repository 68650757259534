$status-icon-size: 44px;

.pres-view-item {
  margin-bottom: 18px;
  cursor: pointer;

  .mui-card {
    @include transition(all 0.25s ease-in-out);

    will-change: transform, border-color;
    transform: scale(1);
    transform-origin: center center;
    border: 2px solid transparent;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0px;
    box-shadow: none;
    overflow: hidden;
    width: 100%;

    .content {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .media {
      border-radius: 4px;
      position: relative;
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

      img,
      .play-container,
      .skeleton {
        border-radius: 4px;

        .right {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }

    .id-container {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-start;
      width: auto;
      height: auto;
      font-size: 0.85rem;
      font-weight: bold;
      z-index: 3;

      span {
        color: #fff;
        background-color: #333;
        font-size: 11px;
        font-weight: 400;
        padding: 0 3px;
        border-top-left-radius: 4px;
      }
    }

    .play-container {
      opacity: 0;
      button,
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 12px;
        text-transform: none;
        color: #e0e0e0;

        .icon-container {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background-color: #e0e0e0;
          border: 1px solid #b4b4b4;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 2px;

          &.disabled {
            border: 1px solid #696969;
            opacity: 0.5;
          }

          &.share {
            svg {
              margin-left: -2px;
            }
          }
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .status-container {
      width: $status-icon-size;
      height: $status-icon-size;
      position: absolute;
      top: 0;
      right: 0;
      left: auto;
      border-top-right-radius: 4px;
      z-index: 3;

      .tri {
        // border-color: #4caf50 #4caf50 transparent transparent;
        border-style: solid;
        border-top-right-radius: 4px;
        border-width: $status-icon-size / 2;
        height: $status-icon-size;
        width: $status-icon-size;
        z-index: 1;

        &.published {
          border-color: #4caf50 #4caf50 transparent transparent;
        }

        &.modified {
          border-color: #dc642f #dc642f transparent transparent;
        }

        &.unpublished {
          border-color: #b6b6b6 #b6b6b6 transparent transparent;

          .icon {
            color: #777;
          }
        }

        .icon {
          position: absolute;
          top: 2px;
          right: 2px;
          bottom: 0;
          fill: #fff;
          width: $status-icon-size / 2;
          height: $status-icon-size / 2;
        }
      }
    }

    .publishing-container {
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 100%;

      .MuiLinearProgress-root {
        height: 8px;
      }

      .progress-inner {
        width: 100%;

        p {
          color: white;
          font-size: 14px;
        }

        .progress-label {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          text-align: right;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(-45deg, #2e7d32, #80b783);
          background-size: 400% 400%;
          animation: gradient 1.5s ease infinite;
        }
      }
    }

    .title {
      margin-bottom: 10px;
      height: 38px;
      line-height: 1.2;
      font-size: 0.8em;
    }

    @media (max-width: $bp-lg) {
      .title {
        font-size: 16px;
      }
    }

    .info {
      height: 0px;
      max-height: 0px;
      overflow: hidden;
      position: relative;
      will-change: max-height, opacity;
      transition: opacity 0.25s ease-in-out;
      opacity: 0;
      visibility: hidden;

      .label,
      .value {
        font-size: 0.575rem;

        &.label {
          font-weight: 500;
        }
      }

      .expanded-info {
        position: relative;
        z-index: 99;
      }
    }
  }

  &.active {
    position: relative;

    .mui-card {
      transform: scale(1.15);
      transform-origin: center center;
      transition: all 0.25s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: var(--bs-gutter-y);
      border: 2px solid #1d9bf1;
      z-index: 100;
      border-radius: 7px;

      .content {
        .title {
          height: unset;
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
      }

      .media {
        border-radius: 0;
        border: none;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%),
          0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%);

        img,
        .play-container,
        .skeleton {
          border-radius: 0;

          &.play-container {
            opacity: 100;
          }

          .right {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      .info {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
        height: auto;
        max-height: 500px;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.25s ease-in-out;
      }
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// FORUM COMMENT FORM

.forum-comment-form-container {

  .col-author {
    @media (min-width: $bp-md) {
      flex: 0 0 20%;
    }
  }

  .forum-comment-form__author {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;

    @media (min-width: $bp-md) {
      flex-direction: column;
      margin-bottom: 0;
    }

    a {
      color: $link-colour;
    }

    .user-image {
      width: 50px;
  
      @media (min-width: $bp-md) {
        width: 80px;
      }
    }

    .username {
      display: block;
      font-size: 0.8rem;
      text-align: center;
      line-height: 1.5;
      margin-bottom: 0;

      @media (min-width: $bp-md) {
        margin-top: 0.25rem;
        margin-bottom: revert;
      }
      
      span {
        display: block;
      }

      a {
        color: $secondary-colour;
      }
    }

    .user-member-date {
      display: block;
      margin-top: auto;
      font-size: 0.7rem;
    }

    .author-flag {
      display: block;
    }
  }

  .comment-form {
    flex: 1;
  }
}

$height: 111px;

.settings-wrap-lg-main {
  .logos {
    .main-wrapper {
      width: 100%;

      .inner-wrapper {
        width: 100%;
        white-space: nowrap;
        height: 200px;
        overflow: auto;
        padding: 2em 1em;

        .logo-wrap {
          display: inline-block;
          transform-origin: center;
          transform: scale(1);
          margin-right: 1.5em;
          border-radius: 5px;
          overflow: hidden;
          transition: transform 100ms ease 0s;
          height: $height;
          width: $height / 9 * 16;

          &:hover,
          &.selected {
            transform: scale(1.1);
            box-shadow: 0px 0px 5px 1px $primary-colour;
          }

          &.no-lg {
            button {
              padding: 0;
            }
          }

          button {
            @include btn-reset();
            height: $height;
            width: $height / 9 * 16;
            padding: 20px;
            background: #e9e9e9;
            // background-size: 20px 20px;
            // background-image: radial-gradient(circle, #777 1px, rgba(0, 0, 0, 0.075) 1px);
            // background:
            //   linear-gradient(-90deg, rgba(0,0,0,.05) 1px, transparent 1px),
            //   linear-gradient(rgba(0,0,0,.05) 1px, transparent 1px), 
            //   linear-gradient(-90deg, rgba(0, 0, 0, .04) 1px, transparent 1px),
            //   linear-gradient(rgba(0,0,0,.04) 1px, transparent 1px),
            //   linear-gradient(transparent 3px, #f9f9f9 3px, #f9f9f9 78px, transparent 78px),
            //   linear-gradient(-90deg, #fff 1px, transparent 1px),
            //   linear-gradient(-90deg, transparent 3px, #fff 3px, #fff 78px, transparent 78px),
            //   linear-gradient(#fff 1px, transparent 1px),
            //   #f2f2f2;
            // background-size:
            //   2px 2px,
            //   2px 2px,
            //   80px 80px,
            //   80px 80px,
            //   80px 80px,
            //   80px 80px,
            //   80px 80px,
            //   80px 80px;

            span {
              display: block;
              height: $height;
              width: $height / 9 * 16;
              line-height: $height;
              color: $light-font-colour;
            }
          }
        }
      }
    }
  }

  .form-control {
    font-size: 0.8em;
    font-family: monospace;
  }
}
.thumbnail-control {
  .chapters,
  .thumbnails {
    // scrollbars
    &::-webkit-scrollbar {
      display: none;
    }

    // ul {
    //   &::-webkit-scrollbar {
    //     display: none;
    //   }

    //   -ms-overflow-style: none;  /* IE and Edge */
    //   scrollbar-width: none;  /* Firefox */
    // }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ul {
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    &.chapters {
      padding: 0 30px;

      button {
        color: white;
      }
    }

    .lft-btn {
      left: 15px;
    }

    .rt-btn {
      right: 15px;
    }

    .lft-btn,
    .rt-btn {
      position: absolute;
      z-index: 100;
      background-color: #f9f9f9;
      -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
      margin-top: -17px;
      svg {
        color: #999;
      }
    }

    .image-list-thumb-container {
      li {
        &:first-of-type {
          padding-left: 30px;
        }
        &:last-of-type {
          padding-right: 30px;
        }
      }
    }
  }

  .MuiPaper-root {
    background-color: #111;

    .MuiChip-root {
      background-color: #f9f9f9;
      color: #242424;
      -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

.viewer-controls-container {
  &.visible {
    opacity: 1;
    transition: opacity 0.35s ease-in-out;
  }

  &.always-visible {
    opacity: 1;
    transition: opacity 0.35s ease-in-out;
  }

  &.always-hide {
    opacity: 0;
    transition: opacity 0.35s ease-in-out;
  }

  opacity: 0;
  transition: opacity 0.35s ease-in-out;
  position: absolute;
  z-index: 1300;

  .help,
  .home {
    background-color: #222222;
    border: 1px solid #444;
    color: white;
    margin-right: 20px;
    padding: 0;

    &.help {
      background-color: #ffd000;
      color: #111;

      &:hover {
        color: #ffd000;
      }

      svg {
        padding: 5px;
      }
    }

    &.home {
      svg {
        padding: 5px;
        fill: #bababa;
      }
      &:hover {
        svg {
          fill: #ffffff;
        }
      }
    }

    &:hover {
      background-color: #333;
    }
  }

  .viewer-controls {
    background-color: #222222;
    border: 1px solid #444;
    border-radius: 50px;
    display: flex;
    padding-left: 7px;

    button {
      color: #bababa;
      padding: 0;

      svg {
        fill: #bababa;
      }

      &:hover {
        color: #ffffff;

        svg {
          fill: #ffffff;
        }
      }

      &#context {
        svg {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }

      &.reveal-controls-presentation-audio {
        svg {
          height: 20px !important;
        }
      }

      &.pager {
        border-radius: 3px;
        min-width: 60px;
      }

      &.next {
        margin-right: 5px;
      }
    }

    .viewer-controls-sub-container {
      display: flex;
      flex-direction: row;
      overflow: hidden;

      &.audio {
        .player-container {
          max-width: 0;
          transition: all 0.8s ease;
          overflow: hidden;
          position: absolute;
          z-index: 2;
          left: 0;
          .audio-player {
            width: 100% !important;
            min-width: 150px;
            border-top: 0;
            border-bottom: 0;
            border-left: 0;
          }
        }

        #reveal-controls-presentation-audio {
          position: relative;
          z-index: 4;
        }

        .reveal-controls-presentation-audio {
          svg {
            &,
            & * {
              fill: #bababa;
            }
          }
          &:hover {
            svg {
              &,
              & * {
                fill: #fff;
              }
            }
          }
        }

        &:not(.edit) {
          #reveal-controls-presentation-audio:hover,
          &:hover {
            & ~ .player-container,
            .player-container {
              max-width: 102%;
              transition: all 0.8s ease;
            }
          }
        }

        &.edit.show {
          & ~ .player-container,
          .player-container {
            max-width: 102%;
            transition: all 0.8s ease;
          }
        }
      }
    }
  }

  #wave {
    height: 70px;
    width: 70px;
    fill: #fff;
  }

  @for $i from 1 through 9 {
    #Line_#{$i} {
      animation: pulse 1s infinite;
      animation-delay: $i * 0.15s;
    }
  }

  @keyframes pulse {
    0% {
      transform: scaleY(1);
      transform-origin: 50% 50%;
    }

    50% {
      transform: scaleY(0.7);
      transform-origin: 50% 50%;
    }

    100% {
      transform: scaleY(1);
      transform-origin: 50% 50%;
    }
  }
}

.context-menu {
  .MuiPaper-root {
    // background-color: #1f1f1f;
    background-color: transparent;
    color: white;
    margin-top: 10px;
    border-radius: 15px;
    // margin-left: -6px;
    // border: 1px solid #444;
    border: transparent;
    box-shadow: none;

    .context-menu-icon-btn {
      background-color: #222;
      border: 1px solid #444;
      color: #bbb;
      height: 32px;
      width: 32px;
      margin-bottom: 10px;

      &:hover {
        color: #fff;
        svg {
          fill: #fff;
        }
      }

      svg {
        width: 20px;
        height: 20px;
        fill: #bbb;
      }

      .autoplay-icon {
        width: 25px;
        height: 25px;
      }
    }

    li {
      font-size: 0.75rem;
      color: #bbbbbb;
      background-color: #1f1f1f;
      transition: color 0.35s ease-in-out, background-color 0.35s ease-in-out;
      padding: 6px 12px;

      .MuiListItemIcon-root {
        min-width: 32px;
      }

      .MuiListItemText-root {
        span {
          font-size: 16px;
        }
      }

      &:hover {
        background-color: #333;
        color: #fff;
        transition: color 0.35s ease-in-out, background-color 0.35s ease-in-out;

        svg {
          color: white;
          transition: color 0.35s ease-in-out;
        }
      }

      svg {
        color: #babababa;
        width: 20px;
        transition: color 0.35s ease-in-out;
      }
    }
  }
}

// info
.info {
  span {
    line-height: 1.4;
  }

  hr {
    background-color: #999999;
  }

  a {
    color: #1d9bf1;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.autoplay-menu-popover {
  border-radius: 8px;
  * {
    box-shadow: none;
  }
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
  }

  .autoplay-menu-container {
    background-color: #222;
    border: 1px solid #444;
    border-radius: 13px;
    display: flex;
    min-width: 180px;
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 0px 5px;

    * {
      font-size: 16px;
      color: #bababa;
    }

    .autoplay-play-btn-row {
      .autoplay-play-btn {
        color: #babababa;
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        padding: 0;

        &:hover {
          color: #fff;
        }
      }
    }
    .autoplay-slider-row {
      padding-left: 5px;
      padding-right: 5px;

      .autoplay-slider {
        .MuiSlider-rail {
          color: #bababa;
        }

        .MuiSlider-track,
        .MuiSlider-thumb {
          color: #fff;
        }
      }
      svg {
        fill: #fff;
      }
    }
  }
}

// Pagination component

$border-radius: 5px;

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem; // same as .page-container

  button {
    &.btn {
      width: 44px;
      height: 44px;
      border-right-width: 0;
      border-radius: 0; // reset default btn border-radius
      padding: 0.25rem 0.5rem;
      color: $primary-colour;

      &.pagination-start,
      &.pagination-end {
        width: auto;
        display: flex;
        align-items: center;
      }

      &.pagination-start {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      &.pagination-end {
        border-right-width: 1px;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      &.active {
        color: #fff;
        border-color: $primary-colour;
        background-color: $primary-colour;
        opacity: 1;
      }

      &:hover {
        &:not(:disabled) {
          background-color: rgba($primary-colour, 0.2);
        }
      }
    }

  }
}
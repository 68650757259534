// LINKS

.links {
  .link-item {
    display: flex;
    flex-direction: column;

    .link-item__content {
      padding: 0;

      .title {
        margin: 0;
        font-weight: 400;
      }
    }

    .link-item__url {
      display: flex;
      align-items: flex-start;
      margin-top: 0.5rem;
      font-size: 1rem;
      line-height: 1.4;
      color: $secondary-colour;

      @media (min-width: $bp-lg) {
        font-size: 0.8rem;
      }
    }

    .link-item__icon {
      width: 1rem;
      min-width: 1rem;
      height: calc(1rem * 1.4);
      margin-right: 0.5rem;
    }

    .link-item__footer {
      @include border-flick();
    }
  }
}

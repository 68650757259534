// CONTACT SINGLE
$abbreviation-size: 60px;
$abbreviation-lg-size: 150px;

.single {
  &.company-directory {
    .article {

      .company-directory__header {
        margin-bottom: 2rem;

        .abbreviation {
          width: $abbreviation-size;
          height: $abbreviation-size;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          border-radius: 50%;
          font-size: 2rem;
          color: #fff;
          background-color: $accent-colour;

          @media (min-width: $bp-lg) {
            width: $abbreviation-lg-size;
            height: $abbreviation-lg-size;
            font-size: 3rem;
          }

          svg {
            width: $abbreviation-size / 2;
            height: auto;

            @media (min-width: $bp-lg) {
              width: $abbreviation-size;
            }
          }


        }

        .title,
        .category {
          margin-bottom: 0;
        }

        .title {
          font-size: 2rem;

          @media (min-width: $bp-lg) {
            font-size: 2.5rem;
          }
        }

        .category {
          font-size: 1.25rem;
          font-weight: 300;
          color: $primary-colour;

          @media (min-width: $bp-lg) {
            font-size: 1.75rem;
          }
        }
      }

      .company-directory__details {
        margin: 2rem 0;

        .details-item {
          align-items: center;
          margin-bottom: 0.25rem;
  
          p {
            margin-bottom: 0;
          }
  
          svg,
          .details-item__value {
            color: $primary-colour;
          }
  
          svg {
            width: 1rem;
            min-width: 1rem;
            height: 1.2rem; // same as label
          }
  
          .details-item__value {
            margin-left: 0.5rem;
            line-height: 1.2;
          }
        }

      }

      .contact-group-item {
        margin-top: 2rem;
        padding-top: 1rem;
        border-top: 1px solid $border-colour;

        h4 {
          margin-bottom: 1rem;
        }

        .contact-group-item__btn {
          width: 3rem;
          height: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          border-radius: 50%;
        }

        .contact-group-item__value {
          text-decoration: none;
          color: $primary-colour;
        }

        .phone {
          .contact-group-item__value {
            color: $primary-colour;
          }
        }

        .email {
          .contact-group-item__value {
            color: $secondary-colour;
          }
        }
      }

    }
  }
}

.cdx-point-container {
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 50px;

  .point-alternate{
    display: none;
  }

  &.index-alt{
    .point-alternate{
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 7em;
    }
    img.cdx-point, button.point-img-btn{
      display: none;
    }
  }
}

.cdx-point .cdx-loader {
  min-height: 200px;
}

.cdx-point-settings-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.cdx-point-btn,
.cdx-point-replace-btn {
  color: #4f4f4f;
  -webkit-user-select: none;
  user-select: none;
  background: #f5f5f5;
  border: none;
  outline: none;
  ;
  border-radius: 10px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  margin: 0 auto;
}

.cdx-point-replace-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.5;
  color: #4f4f4f;
  -webkit-user-select: none;
  user-select: none;
  background: #f5f5f5;
  border: none;
  outline: none;
  ;
  border-radius: 10px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
}

.cdx-point-replace-btn:hover {
  opacity: 1;
}

.cdx-point-btn svg,
.cdx-point-replace-btn svg {
  margin-right: 5px;
}

.cdx-point-input {
  padding: 0;
  margin: 0;
  outline: none;
  cursor: text;
  height: auto;
}

.cdx-point-img-container {
  width: 150px;
  height: 150px;
  position: relative;
}

.cdx-point-content-container {
  height: 150px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-left: 50px;
}

.point-heading {
  min-height: 40px;
}

.point-heading[data-placeholder]:empty::before,
.point-body[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.point-heading[data-placeholder]:empty::before,
.point-body[data-placeholder]:empty::before {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}
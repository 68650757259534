// NAVBAR

$nav-font-size: 1rem;
$nav-font-size-lg: 0.9rem;
$nav-font-size-standard: 0.75rem;

.navbar-container {
  width: 100%;
  position: fixed;
  top: $header-height-xl;
  // height: var(--height);
  height: calc(100vh - #{$header-height-xl});
  z-index: 1000;
  
  @media (min-width: $bp-lg) {
    width: navbar-width($bp-lg);
    top: $header-height-xl;
  }

  @media (min-width: $bp-xl) {
    width: navbar-width($bp-xl);
    top: $header-height-xl;
  }

  @media (min-width: $bp-xxl) {
    width: navbar-width($bp-xxl);
  }

  /* ========================================
     MOBILE OVERLAY
  ======================================== */
  .mobile-overlay {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;
  }

  /* ========================================
     NAVBAR
  ======================================== */
  .navbar {
    width: navbar-width();
    max-width: 240px;
    height: 100%;
    display: block;
    // padding: 20px 0 env(safe-area-inset-bottom) 0;
    padding: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    // background-color: #fff;
    // background-color: $light-grey;
    background-color: #fafafa;
    // border-right: 1px solid lighten($border-colour, 5%);
    transform: translateX(0);
    transition: transform 500ms ease;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.03);

    &:hover {
      overflow-y: auto;
    }

    @media (min-width: $bp-lg) {
      width: navbar-width($bp-lg);
    }
  
    @media (min-width: $bp-xl) {
      width: navbar-width($bp-xl);
    }
  
    @media (min-width: $bp-xxl) {
      width: navbar-width($bp-xxl);
    }
  
    ul {
      width: navbar-width();
      max-width: 240px;
      margin: 0;
      padding: 10px 0 0;
      // border-bottom: 1px solid $border-colour;

      &:first-of-type {
        padding-top: 15px;
      }

      &:after {
        content: " ";
        position: relative;
        width: calc(100% - 30px);
        height: 1px;
        // border-bottom: 1px solid lighten($border-colour, 5%);
        display: block;
        // margin: 10px auto 0;
      }

      @media (min-width: $bp-lg) {
        width: navbar-width($bp-lg);
      }
    
      @media (min-width: $bp-xl) {
        width: navbar-width($bp-xl);
      }
    
      @media (min-width: $bp-xxl) {
        width: navbar-width($bp-xxl);
      }

      .group-label{
        padding: 0.7rem 0 0.3rem 1rem;
        font-size: 14px;
        color: #a0a0a0;
        font-weight: 500;
      }

      .nav-item {
        display: flex;
        list-style: none;
        font-size: 14px;
        font-weight: 400;

        @media (min-width: $bp-lg) {
          // font-size: $nav-font-size-standard;
        }
  
        .nav-link {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0.2em 0 0.2em 1rem;
          color: #181818;
          // color: $dark-font-colour;

          .nav-item__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.5em;
            border-radius: 50%;
            background: transparent;

            svg {
              width: $nav-font-size;
              height: $nav-font-size;

              @media (min-width: $bp-lg) {
                width: $nav-font-size-lg;
                height: $nav-font-size-lg;
              }
            }
          }

          .nav-item__label {
            margin-left: 1rem;
            display: block;
          }

          .nav-item__indicator {
            width: 1rem;
            height: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: 15px;
            border-radius: 50%;
            font-size: 0.75em;
            font-weight: 500;
            color: $pdfIconColour;
            background-color: rgba(#C00D0D, 0.1);
          }
  
          &.active {
            color: #C00D0D;

            .nav-item__icon {
              background-color: rgba(#C00D0D, 0.2); 
            }
          }

          &:hover {
            color: #C00D0D;
          }
        }
  
      }
    }

  }

  /* ========================================
     NAVIGATION CONTAINER: EXPANDED
  ======================================== */
  &.expanded {
    // Only show on mobile
    @media (max-width: 991px) {
      .mobile-overlay {
        opacity: 1;
        visibility: visible;
        transition: opacity 500ms ease;
      }
    }

  }

  /* ========================================
     NAVIGATION CONTAINER: COLLAPSED
  ======================================== */
  &.collapsed {
    width: 0;

    .navbar {
      transform: translateX(-100%);
      transition: transform 500ms ease;
    }
  }

  @include custom-scrollbar();
  
}
$small-btn-size: 30px;
$large-btn-size: 120px;
$xs-svg-size: 35%;
$small-svg-size: 40%;
$md-svg-size: 50%;
$svg-size: 45%;
$large-svg-size: 60%;
$xl-svg-size: 65%;
$xxl-svg-size: 75%;

.block-btn {
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #dae0e7;
  background-color: #edf7fe;
  border-radius: 5px;
  transition: all 0.3s ease;
  padding: 0;
  font-weight: 500 !important;
  color: #16324f !important;

  &.small {
    width: $small-btn-size;
    height: $small-btn-size;
    max-width: $small-btn-size;
    max-height: $small-btn-size;
    min-width: $small-btn-size;
    min-height: $small-btn-size;
  }

  &.large {
    width: $large-btn-size;
    height: $small-btn-size;
    max-width: $large-btn-size;
    max-height: $small-btn-size;
    min-width: $large-btn-size;
    min-height: $small-btn-size;
    justify-content: space-evenly;

    svg {
      width: auto;
    }
  }

  &.large-icon {
    svg {
      width: $large-svg-size;
      height: $large-svg-size;
      max-width: $large-svg-size;
      max-height: $large-svg-size;
    }
  }

  &.xl-icon {
    svg {
      width: $xl-svg-size;
      height: $xl-svg-size;
      max-width: $xl-svg-size;
      max-height: $xl-svg-size;
    }
  }

  &.xxl-icon {
    svg {
      width: $xxl-svg-size;
      height: $xxl-svg-size;
      max-width: $xxl-svg-size;
      max-height: $xxl-svg-size;
    }
  }

  &.md-icon {
    svg {
      width: $md-svg-size;
      height: $md-svg-size;
      max-width: $md-svg-size;
      max-height: $md-svg-size;
    }
  }

  &.sm-icon{
    svg {
      width: $small-svg-size;
      height: $small-svg-size;
      max-width: $small-svg-size;
      max-height: $small-svg-size;
    }
  }

  &.xs-icon{
    svg {
      width: $xs-svg-size;
      height: $xs-svg-size;
      max-width: $xs-svg-size;
      max-height: $xs-svg-size;
    }
  }

  svg {
    width: $svg-size;
    height: $svg-size;
    max-width: $svg-size;
    max-height: $svg-size;
    fill: #16324f;
    transition: all 0.3s ease;

    * {
      fill: #16324f;
    }
  }

  &.clear {
    &:hover {
      background-color: #bf1e2c;
      border-color: #bf1e2c;
    }
  }

  &:hover {
    background-color: #1d9bf1;
    border-color: #1d9bf1;

    color: #ffffff !important;

    svg {
      fill: #ffffff;
      transition: all 0.3s ease;

      * {
        fill: #ffffff;
      }
    }
  }

  &.embed-btn, &.embed-replace-btn{
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    margin: 0px auto 0px auto;;
  }
}


.cdx-media-container.has-img, 
.cdx-media-container.has-video, 
.front.has-img, 
.cdx-point-img-container, 
.cdx-contact-container,
.cdx-inline-media-container.has-img,
.ce-block.icon {
  position: relative;

  .block-btn {
    position: absolute;
    right: 10px;
    top: 58px;
    
    &.clear, &.alt {
      top: 20px;
    }
  }
}

.map-container{
  .block-btn {
    position: absolute;
    top: 10px;
    left: 10px;

    &.clear, &.alt{
      left: auto;
      right: 10px;
    }
  }
}

.ce-block.table{
  .tc-toolbox{
    &.tc-toolbox--column, &.tc-toolbox--row{
      svg{
        rect{
          fill: #1d9bf1;
        }
        circle{
          fill: #fff;
        }
      }
    }
    &:hover{

    }
  }
}
// HEADER

$dropdown-font-size: 1rem; /* same as nav */
$dropdown-font-size-lg: 0.8rem; /* same as nav */

$user-image-size: 30px;

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
  // -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.5);
  // -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.5);
  // box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.5);
  border-bottom: 1px solid #b7b7b7;
  background: #fff;
  height: $header-height-xl;

  &.scrolled {
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.2s, -moz-box-shadow 0.2s, -webkit-box-shadow 0.2s;

    .branding {
      img {
        max-height: 35px !important;
        transition: all 0.5s ease-in-out;
      }
    }
  }

  .container-fluid {
    height: 100%;

    .row {
      height: 100%;

      & > * {
        &:not(.col-auto),
        &:not(.branding-container) {
          max-width: initial;
          width: initial;
        }
      }

      .header-branding {
        padding: initial;
      }
    }
  }

  &.show-false {
    display: none;
  }

  // @include gradient();

  .hmg-container {
    padding: 0 0 0 1rem;
    margin-right: 0.8em;
  }

  .navbar-btn {
    @include btn-reset();
    padding: 0 0.15em;

    svg {
      color: $primary-colour;
    }
  }

  .branding-container {
    img {
      transition: all 0.5s ease-in-out;
      max-height: 70px;
    }

    &.scrolled,
    &.presentation-variant {
      // top: 20% !important;

      &.presentation-variant {
        top: 20% !important;

        @media (max-width: $bp-md) {
          top: 50% !important;
        }

        img {
          max-height: 35px;
        }
      }

      @media (min-width: $bp-md) {
        transition: top 0.2s, width 0.2s, background-color 1s, margin-left 0.5s,
          left 0.5s;
        width: 250px !important;
        background-color: rgba(0, 0, 0, 0);
        width: 220px;
        top: 25% !important;
      }
    }

    &.nav-expanded {
      transition: top 0.2s, width 0.2s, background-color 1s, margin-left 0.5s,
        left 0.5s;

      @media (min-width: $bp-lg) and (max-width: $bp-xl) {
        margin-left: 60px;
      }
      @media (min-width: $bp-xl) {
        margin-left: 50px;
      }
      @media (min-width: $bp-xxl) {
        left: 600px;
        margin-left: calc(((100% - 1490px) * 0.3) + 215px);
      }

      @media (min-width: $bp-md) and (max-width: $bp-lg) {
        &:not(.scrolled) {
          width: 220px !important;
          background-color: rgba(0, 0, 0, 0);
          top: 25% !important;
        }
      }
    }

    @media (max-width: $bp-sm) {
      flex-grow: 3;
    }

    @media (min-width: $bp-sm) and (max-width: $bp-md) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media (min-width: $bp-md) {
      position: absolute;
      width: 360px !important;
      padding: 0 20px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 0%);
      background-color: #fff;
      transition: top 0.2s, width 0.2s, background-color 1s, margin-left 0.5s,
        left 0.5s;
    }

    .branding {
      width: 150px !important;
      display: block;
      margin: 0 auto;

      @media (min-width: $bp-md) {
        width: 100% !important;
      }

      @media (min-width: $bp-sm) {
        margin: 0;
      }
    }
  }

  .search-container {
    position: relative;
    z-index: 99;
    margin-left: 1rem;

    .search {
      width: 150px;
      height: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: auto;
      border-radius: 5px;
      font-size: 0.8rem;
      color: $normal-font-colour;
      background: #fff;
      border: 1px solid $primary-colour;
      transition: width 500ms ease;

      .flex-grow-1 {
        height: 100%;
        padding: 0;
        display: flex;
        align-items: center;

        input {
          height: 80%;
          width: 100%;
          padding: 0.25em 0.5em;
          font-size: 0.7rem;
          border: none;
          border-radius: 5px;
          outline: none;
        }
      }

      button {
        @include btn-reset();
        padding: 0.25em 0.5em;
        display: flex;
        align-items: center;

        svg {
          width: 0.8rem;
          height: auto;
          color: $primary-colour;
          transition: color 250ms ease;
        }

        &:hover {
          svg {
            color: $accent-colour;
            transition: color 250ms ease;
          }
        }
      }

      &:focus-within {
        width: 100%;
        border: 1px solid $primary-colour;
        transition: width 500ms ease;
      }

      @media (max-width: $bp-lg) {
        max-width: 170px;
      }
      @media (max-width: $bp-md) {
        max-width: 100px;
      }
    }
  }

  .small-search {
    button {
      @include btn-reset();
      padding: 0.25em 0.5em;
      display: flex;
      align-items: center;

      svg {
        width: 1rem;
        height: auto;
        color: $primary-colour;
        transition: color 250ms ease;
      }
    }
  }

  .login-button {
    height: $user-image-size;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $primary-colour;

    svg {
      margin-right: 0.5rem;
      border-radius: 50%;
    }
  }

  .user-image {
    font-size: 16px !important;
    border: 1px solid $primary-colour;
  }

  .user {
    display: flex;
    align-items: center;

    &.dropdown-toggle {
      @include btn-reset();

      &::after {
        content: none; // hide default caret icon
      }
    }

    .user__image {
      width: $user-image-size;
      height: $user-image-size;
      border: 1px solid $primary-colour;
      position: relative;
    }

    .user__name {
      margin: 0 1em;
      font-size: 0.8rem;
      // color: $normal-font-colour;
      color: $primary-colour;
    }

    .dropdown-main__indicator {
      position: absolute;
      top: 0;
      left: (30px - 7px); // image width - width / 2
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      border-radius: 50%;
      font-size: 0.5em;
      font-weight: 500;
      color: $pdfIconColour;
      background-color: rgba(255, 255, 255, 0.9);
    }

    svg {
      width: 1rem;
      height: auto;
      // color: $normal-font-colour;

      &.user__toggle {
        color: $primary-colour;
      }
    }
  }

  /* ========================================
     DROPDOWN
  ======================================== */
  .dropdown {
    .dropdown-menu {
      min-width: 11rem; // increase default bootstrap size
      font-size: $dropdown-font-size;

      @media (min-width: $bp-lg) {
        min-width: 10rem; // restore default bootstrap size
        font-size: $dropdown-font-size-lg;
      }
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      padding: 0.25em 1em;
      color: $dark-font-colour;

      .dropdown-item__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5em;
        border-radius: 50%;
        background: transparent;

        svg {
          width: $dropdown-font-size;
          height: $dropdown-font-size;

          @media (min-width: $bp-lg) {
            width: $dropdown-font-size-lg;
            height: $dropdown-font-size-lg;
          }
        }
      }

      .dropdown-item__label {
        margin-left: 0.5em;
        display: block;
      }

      &.active {
        color: $secondary-colour;
        background-color: transparent;

        .dropdown-item__icon {
          background-color: rgba($secondary-colour, 0.2);
        }
      }

      &:hover,
      &:focus {
        color: $secondary-colour;
        background-color: transparent;
      }
    }

    .dropdown-item__indicator {
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      border-radius: 50%;
      font-size: 0.5em;
      font-weight: 500;
      color: $pdfIconColour;
      background-color: rgba($pdfIconColour, 0.1);
    }
  }
}

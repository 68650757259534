.cdx-nested-list.list-custom{

  *{
    color: inherit !important;
  }
  .cdx-nested-list__item{
    &::before{
      color: inherit !important;
    }
  }
}

.list_block.content_block{
  .li-custom{
    &::before{
      color: inherit !important;
    }
  }
}
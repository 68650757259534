// GROUP MODALS (Create * Edit notice groups)

$modal-base-font-size: 0.8rem;

.group-modal {
  @include custom-scrollbar() {
    ::-webkit-scrollbar-track {
      background-color: #fff;
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
  };

  .modal-dialog {
    max-width: 90%;
    height: 85vh;
    margin: 7.5vh auto;

    @media (min-width: $bp-md) {
      height: 75vh;
    }

    @media (min-width: $bp-lg) {
      max-width: 75%;
    }

    .modal-content {
      height: 100%;
      font-size: $modal-base-font-size;
    }
    
    .modal-header {
      padding: 1rem 2rem;
    }
  
    .modal-body {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0;

      @media (min-width: $bp-md) {
        overflow: hidden;
      }
    }

    /* Modal title
    ======================================== */
    .modal-title {
      display: flex;
      align-items: center;
      word-break: break-all;
      border: 1px solid $border-colour;
      border-radius: 0.25rem;
      overflow: hidden;

      // Same styles as bootstrap .form-control
      &.is-focused {
        outline: 0;
        border-color: rgba($primary-colour, 0.5);
        box-shadow: 0 0 0 0.2rem rgba($primary-colour, 0.25);
      }
  
      .group-name-placeholder {
        color: $light-font-colour;
      }

      .icon-container {
        margin-right: 0.5rem;
        padding: 0 0.25rem;
        border-right: 1px solid $border-colour;
        background-color: $light-grey;

        svg {
          width: 20px;
          height: auto;
          margin: 0;
          color: $primary-colour;
        }
      }

      input {
        border: none;
        padding: 0;
        font-weight: inherit;
        color: inherit;
        flex: 1 1 auto;

        &:focus-visible {
          outline: 0;
        }
      }

    }
  
    .modal-footer {
      display: block;
      margin-top: auto;
      padding: 1rem 2rem;
  
      > {
        * {
          margin: 0;
        }
      }
  
      button {
        font-size: inherit;
      }
    }

    /* Content section
    ======================================== */
    .users-section,
    .members-section {
      @media (min-width: $bp-md) {
        height: 100%;
      }
    } 

    .users-section {
      padding: 2rem;

      @media (min-width: $bp-md) {
        overflow-y: auto;
        overflow-x: hidden;
        border-right: 1px solid $border-colour;
      }
    }

    .members-section {
      display: flex;
      flex-direction: column;

      .members-section__content {
        padding: 2rem;

        @media (min-width: $bp-md) {
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
    }
  }
}
// HOME

// border: 1px solid #dddfe2;
$social-border-radius: 5px;

.home {
  .welcome {
    h1 {
      margin-bottom: 0;

      &:first-of-type {
        font-weight: 300;
      }
    }
  }

  .home-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 1rem;
    border-bottom: 2px solid $border-colour;
    transition: all 500ms ease;

    .home-item__header {
      color: $primary-colour;
    }

    .home-item__icon {
      width: 20px;
      height: auto;
    }

    .home-item__title {
      margin-bottom: 0;
      font-weight: 400;
    }

    .home-item__Link {
      display: block;
      position: relative;
      text-decoration: none;
    }

    .home-item__image {
      width: 100%;
      position: relative;
      overflow: hidden;
      border: 1px solid lighten($border-colour, 5%);

      img {
        @include transition-scale(1);
      }
    }

    .home-item__content {
      width: 100%;
      margin-top: 1rem;
      color: $dark-font-colour;

      .title {
        font-size: 1.5rem;
        @include transition-colour();

        @media (min-width: $bp-lg) {
          font-size: 1.25rem;
        }
      }

      .excerpt {
        font-size: 1rem;

        @media (min-width: $bp-lg) {
          font-size: 0.8rem;
        }
      }
      
    }

    &:hover {
      .home-item__image {
        img {
          @include transition-scale(1.1);
        }
      }

      .title {
        @include transition-colour();
        color: $secondary-colour;
      }
    }
  }

  .twitter-container {
    .twitter-container__header {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      .twitter-brand {
        display: flex;

        span {
          margin-left: 0.25rem;
          font-weight: normal;
          color: $twitter-blue;
        }

        svg {
          fill: $twitter-blue;
          stroke: transparent;
        }
      }

      .twitter-handle {
        margin: 0 0 0 0.5rem;
      }
    }

    .twitter-container__timeline {
      border: 1px solid rgb(235, 238, 240); // twitter grey
      border-radius: $social-border-radius;

      > div {
        display: flex; // for alignment with facebook

        .twitter-timeline-rendered {
          width: 100%;
        }
      }
    }
  }

  .facebook-container {
    .facebook-container__header {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      .facebook-brand {
        display: flex;

        .logo {
          width: 24px;
        }

        span {
          margin-left: 0.25rem;
          font-weight: normal;
          color: $facebook-blue;
        }

        svg {
          fill: $facebook-blue;
          stroke: transparent;
        }
      }

      .facebook-handle {
        margin: 0 0 0 0.5rem;
      }
    }

    .facebook-container__timeline {
      display: flex; // for alignment with twitter

      iframe {
        border-radius: $social-border-radius;
      }
    }
  }
}

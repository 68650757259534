// BLOG

.blog {

  .blog-item {
    display: flex;
    flex-direction: column;

    .blog-item__content {
      .title {
        @media (min-width: $bp-sm) {
          @include line-clamp(3);
        }

        @media (min-width: $bp-lg) {
          @include line-clamp(2);
        }
      }
    }

    .blog-item__footer {
      // Flick height = 75%;
      @include border-flick();
      .timestamp__text{
        background-color: #e6e6e6;
        border-radius: 5px;
        padding: 0 5px;
        color: #333;
      }
    }

  }
  
}
.tc-wrap {
  --color-background: #f9f9fb;
  --color-text-secondary: #7b7e89;
  --color-border: #e8e8eb;
  --cell-size: 34px;
  --toolbox-icon-size: 18px;
  --toolbox-padding: 6px;
  --toolbox-aiming-field-size: calc(
    var(--toolbox-icon-size) + 2 * var(--toolbox-padding)
  );
  border-left: 0;
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: var(--toolbox-icon-size);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: calc(100% - var(--cell-size)) var(--cell-size);
}

.tc-wrap--readonly {
  grid-template-columns: 100% var(--cell-size);
}

.tc-wrap svg {
  vertical-align: top;
  fill: currentColor;
}

.tc-table {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  font-size: 14px;
  /* border-top: 1px solid var(--color-border); */
  line-height: 1.4;
}

.tc-table:after {
  width: var(--cell-size);
  height: 100%;
  left: calc(-1 * var(--cell-size));
  top: 0;
}

// .tc-table:after,
// .tc-table:before {
//   position: absolute;
//   content: "";
// }

// .tc-table:before {
//   width: 100%;
//   height: var(--toolbox-aiming-field-size);
//   top: calc(-1 * var(--toolbox-aiming-field-size));
//   left: 0;
// }

.tc-table--heading .tc-row:first-child {
  font-weight: 600;
  /* border-bottom: 2px solid var(--color-border); */
}

.tc-table--heading .tc-row:first-child [contenteditable]:empty:before {
  content: attr(heading);
  color: var(--color-text-secondary);
}

.tc-table--heading .tc-row:first-child:after {
  bottom: -2px;
  /* border-bottom: 2px solid var(--color-border) */
}

.tc-add-column,
.tc-add-row {
  display: flex;
  color: var(--color-text-secondary);
}

.tc-add-column {
  padding: 9px 0;
  justify-content: center;
  /* border-top: 1px solid var(--color-border) */
}

.tc-add-row {
  height: var(--cell-size);
  align-items: center;
  padding-left: 12px;
  position: relative;
}

// .tc-add-row:before {
//   content: "";
//   position: absolute;
//   right: calc(-1 * var(--cell-size));
//   width: var(--cell-size);
//   height: 100%;
// }

.tc-add-column,
.tc-add-row {
  opacity: 0.6;
  cursor: pointer;
  will-change: background-color;
  background-color: #edf7fe !important;
  border-radius: 5px;
  transition: all 0.35s ease;

  &.tc-add-row {
    margin-top: 1px;
  }

  svg {
    transition: all 0.35s ease;
    max-height: 40%;
    max-width: 40%;
    height: auto;
    width: auto;

    &,
    * {
      fill: #16324f;
      stroke: #16324f;
    }
  }

  &:hover {
    background-color: #1d9bf1 !important;
    opacity: 1;
    transition: all 0.35s ease;

    svg {
      &,
      * {
        fill: #fff !important;
        stroke: #fff;
        transition: all 0.35s ease;
      }
    }
  }
}

.tc-row {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  position: relative;
  grid-auto-flow: column;
  // grid-template-columns: minmax(10px, 1.3fr) !important;
  grid-auto-columns: minmax(10px, 1fr);
  /* border-bottom: 1px solid var(--color-border); */
}

.tc-row:after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: var(--cell-size);
  height: 100%;
  bottom: -1px;
  right: calc(-1 * var(--cell-size));
  /* border-bottom: 1px solid var(--color-border) */
}

.tc-row--selected {
  background: var(--color-background);
}

.tc-row--selected:after {
  background: var(--color-background);
}

.tc-cell {
  /* border-right: 1px solid var(--color-border); */
  padding: 6px 12px;
  overflow: hidden;
  outline: none;
  line-break: normal;
}

.tc-cell--selected {
  background: var(--color-background);
}

.tc-wrap--readonly .tc-row:after {
  display: none;
}

.tc-toolbox {
  --toolbox-padding: 6px;
  --popover-margin: 30px;
  --toggler-click-zone-size: 30px;
  --toggler-dots-color: #7b7e89;
  --toggler-dots-color-hovered: #1d202b;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s;
  will-change: left, opacity;
}

.tc-toolbox--column {
  top: calc(-1 * var(--toggler-click-zone-size));
  -webkit-transform: translateX(calc(-1 * var(--toggler-click-zone-size) / 2));
  transform: translateX(calc(-1 * var(--toggler-click-zone-size) / 2));
  will-change: left, opacity;
}

.tc-toolbox--row {
  left: calc(-1 * var(--popover-margin));
  -webkit-transform: translateY(calc(-1 * var(--toggler-click-zone-size) / 2));
  transform: translateY(calc(-1 * var(--toggler-click-zone-size) / 2));
  margin-top: -1px;
  will-change: top, opacity;
}

.tc-toolbox--showed {
  opacity: 1;
}

.tc-toolbox .tc-popover {
  position: absolute;
  top: 0;
  left: var(--popover-margin);
}

.tc-toolbox__toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--toggler-click-zone-size);
  height: var(--toggler-click-zone-size);
  color: var(--toggler-dots-color);
  opacity: 0;
  transition: opacity 0.15s ease;
  will-change: opacity;
}

.tc-toolbox__toggler:hover {
  color: var(--toggler-dots-color-hovered);
}

.tc-wrap:hover .tc-toolbox__toggler {
  opacity: 1;
}

.tc-popover {
  --color-border: #eaeaea;
  --color-background: #fff;
  --color-background-hover: rgba(232, 232, 235, 0.49);
  --color-background-confirm: #e24a4a;
  --color-background-confirm-hover: #d54040;
  --color-text-confirm: #fff;
  background: var(--color-background);
  border: 1px solid var(--color-border);
  box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  border-radius: 6px;
  padding: 6px;
  display: none;
  will-change: opacity, transform;
}

.tc-popover--opened {
  display: block;
  -webkit-animation: menuShowing 0.1s cubic-bezier(0.215, 0.61, 0.355, 1)
    forwards;
  animation: menuShowing 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

.tc-popover__item {
  display: flex;
  align-items: center;
  padding: 2px 14px 2px 2px;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tc-popover__item:hover {
  background: var(--color-background-hover);
}

.tc-popover__item:not(:last-of-type) {
  margin-bottom: 2px;
}

.tc-popover__item-icon {
  display: inline-flex;
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  background: var(--color-background);
  border-radius: 5px;
  border: 1px solid var(--color-border);
  margin-right: 8px;
}

.tc-popover__item-label {
  line-height: 22px;
  font-size: 14px;
  font-weight: 500;
}

.tc-popover__item--confirm {
  background: var(--color-background-confirm);
  color: var(--color-text-confirm);
}

.tc-popover__item--confirm:hover {
  background-color: var(--color-background-confirm-hover);
}

.tc-popover__item--confirm .tc-popover__item-icon {
  background: var(--color-background-confirm);
  border-color: rgba(0, 0, 0, 0.1);
}

.tc-popover__item--confirm .tc-popover__item-icon svg {
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
  -webkit-transform: rotate(90deg) scale(1.2);
  transform: rotate(90deg) scale(1.2);
}

.tc-popover__item--hidden {
  display: none;
}

@-webkit-keyframes menuShowing {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-8px) scale(0.9);
    transform: translateY(-8px) scale(0.9);
  }

  70% {
    opacity: 1;
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes menuShowing {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-8px) scale(0.9);
    transform: translateY(-8px) scale(0.9);
  }

  70% {
    opacity: 1;
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.tc-csv-file-input {
  position: absolute;
  right: 65px;
  width: 100px;
  height: 40px;
  top: -65px;
}

.csv-file-input {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  line-height: 1 !important;
  color: #333 !important;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  background-color: #eff2f5;
  opacity: 1;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  height: 100%;
}

.csv-file-input:hover {
  opacity: 1;
}

.csv-file-input input {
  display: none;
}

.map-container {
  position: relative;
  display: inline-block;
  min-width: 50px;
}

.map .cdx-loader {
  min-height: 200px;
}

.map-settings-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.map-btn,
.map-replace-btn,
.map-fallback-btn {
  color: #4f4f4f;
  -webkit-user-select: none;
  user-select: none;
  background: #f5f5f5;
  border: none;
  outline: none;;
  border-radius: 10px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 998;
}

.map-close {
  color: #4f4f4f;
  -webkit-user-select: none;
  user-select: none;
  background: #f5f5f5;
  border: none;
  outline: none;;
  border-radius: 10px;
  min-width: 30px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-replace-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.5;
  color: #4f4f4f;
  -webkit-user-select: none;
  user-select: none;
  background: #f5f5f5;
  border: none;
  outline: none;;
  border-radius: 10px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
}

.map-replace-btn:hover,
.map-close:hover {
  opacity: 1;
}

.map-btn svg,
.map-replace-btn svg,
.map-close svg {
  max-width: 20px;
  max-height: 20px;
}

/**
 * Modal
 */
.map-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.map-modal-inner {
  width: 50%;
  height: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  .map-block-modal-label{
    font-weight: 600 !important;
    color: #1d9bf1 !important;
  }
  .modal-fallback-img-container{
    margin-top: 20px;
  }

  .map-fallback-btn{
    max-width: 50px !important;
    max-height: 50px !important;
  }
}

.map-modal.show {
  z-index: 999;
  visibility: visible;
  opacity: 1;
}

.map-input {
  padding: 0;
  margin: 0;
  outline: none;
  cursor: text;
  border-bottom: 1px solid #dedede;
  font-size: 18px !important;
}

.map-input:focus {
  border-bottom: 1px solid #bababa;
}

.map-input[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.map-input[data-placeholder]:empty::before {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}

.modal-fallback-img-container{
  display: flex;
  height: 180px;
  position: relative;
}

.modal-fallback-img{
  height: 100%;
  width: auto;
}

.map-fallback-btn{
  position: absolute;
  top: 10px;
  left: 10px;
}
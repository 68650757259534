.slide-notes {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  max-height: 500px;
  bottom: 0;
  transition: height linear;
  pointer-events: none;

  *{
    pointer-events: all;
  }
  
  .slide-notes-toggle {
    z-index: 21;
    width: 50px;
    height: 25px;
    background-color: rgba(237, 247, 254, 0.1);
    border-radius: 10rem 10rem 0 0;
    border: solid 1px rgba($color: #666666, $alpha: 0.1);
    border-width: 1px 1px 0 1px;
    transition: border 0.5s ease background-color 0.5s ease;

    &:hover {
    border: solid 1px rgba($color: #666666, $alpha: 1);
    border-width: 1px 1px 0 1px;
    background-color: rgba(237, 247, 254, 1);
    transition: border 0.5s ease background-color 0.5s ease;
    }
  }

  .slide-notes-container {
    width: 100%;
    height: 100%;
    bottom: 0;
    background-color: #ffffff;
    min-height: 20px;
    z-index: 20;
    display: flex;
    justify-content: center;

    .notes-resizer {
      position: absolute;
      width: 100%;
      height: 20px;
      z-index: 30;
      background-color: #edf7fe;
      cursor: ns-resize;
      display: flex;
      justify-content: center;

      svg {
        height: 100%;
      }
    }

    .slide-notes-input {
      margin-top: 2%;
      width: 90%;
      height: auto;
      margin-bottom: 1%;

      .MuiInputBase-root {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        align-items: start;
      }
    }
  }
}

.hyperlink-input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  font-size: 12px;
  height: 40px;
  padding: 0 10px;
}

.null-btn{
  position: relative;
  background-color: white;
}

.null-btn::after {
  content: "";
  position: absolute;
  border-top: 2px solid red;
  width: 17px;
  transform: rotate(45deg);
  transform-origin: 40% 160%;
  left: -1px;
  top: 5px;
}

.hyperlink-color-selector {
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
}

.hyperlink-color-item-btn {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border-radius: 50px;
  box-shadow: unset;
  border: 2px solid #ccc;
}

.colorCircleActive {
  transform: scale(1.3);
}
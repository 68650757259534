// CONTACTS

.contacts {

  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $page-margin;
    border: 1px solid $border-colour;
    border-radius: 5px;
    font-size: 0.8rem;
    color: $normal-font-colour;
    background-color: #fff;

    input {
      width: 100%;
      padding: 0.25em 0.5em;
      border: none;
      border-radius: 5px;
      outline: none;

      &:focus {
        box-shadow: initial;
      }
    }

    button {
      @include btn-reset();
      padding: 0.25em 0.5em;

      svg {
        width: 1rem;
        height: auto;
        color: $primary-colour;
        transition: color 250ms ease;
      }

      &:hover {
        svg {
          color: $accent-colour;
          transition: color 250ms ease;
        }
      }
    }

    &:focus-within {
      border-color: $primary-colour;
      border-color: transparent;
      box-shadow: 0 0 0 3px rgba($primary-colour, 0.4);
    }

  }

}
// NEWS

.news {

  .sticky-container {
    a:hover{
      text-decoration: none;
    }

    @media (max-width:576px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .news-item {
    min-height: 135px;
    display: flex;
    flex-direction: column;

    @media (min-width: $bp-sm) {
      min-height: initial;
    }

    @media (min-width: $bp-lg) {
      margin-bottom: 50px;
    }

    .news-item__content {
      @media (min-width: $bp-sm) {
        padding-left: 0;
      }

      .title {
        @media (min-width: $bp-sm) {
          margin: 0.75rem 0; 
        }
      }

      .excerpt {
        @include line-clamp(2);
      }

    }

    .news-item__footer {
      // Flick height = 75%;
      @include border-flick();
      .timestamp__text{
        background-color: #e6e6e6;
        border-radius: 5px;
        padding: 0 5px;
        color: #333;
      }
    }

    // STICKY ITEM
    &.sticky {

      .gradient-container {
        display: flex;
        position: relative;

        .d-flex{
          color: #FFFF;
          position: relative;
          z-index: 10;
        }

        &>div:nth-of-type(1){
          padding-left: 0px;
          padding-right: 0;
        }
        
        @media (max-width: 576px) {
          flex-direction: column;
         
          &>div:nth-of-type(2){
            padding: 0 15px;
          }
          
          &::after{
            content:'';
            position:absolute;
            left:0; 
            bottom: 0%;
            width:100%; height:100%;
            display:inline-block;  
            background: rgb(49,47,64);
            background: linear-gradient(0deg, rgba(49,47,64,1) 0%, rgba(84,46,89,1) 45%, rgba(255,255,255,0) 70%); 
          }
        }

        @media (min-width:576px) {
          flex-direction: row;
          
          &>div:nth-of-type(2){
            padding-top: 2rem;
            padding-right: 2rem;
          }

          &::after{
            content:'';
            position:absolute;
            top:0;
            width:100%; height:100%;
            display:inline-block;  
            background: rgb(49,47,64);
            background: linear-gradient(270deg, rgba(49,47,64,1) 0%, rgba(84,46,89,1) 50%, rgba(255,255,255,0) 70%); 
          }

          .excerpt.text-break{
            margin-bottom: 3rem;
          }
        }
      }

      .footer-container{
        .news-item__footer {
          padding-right: 0;
          border-bottom: initial;
          border-right: initial;
          border-bottom-right-radius: initial;
  
          &::after {
            content: none;
          }

          .sticky__timestamp__text {
            color: #fff;
            font-size: 0.7rem;
            margin-left: 0.25em;
          }
        }

        .favourite-btn {
          color: #fff;
  
          &.filled {
            svg {
              fill: #fff;
            }
          }
        }

        @media (max-width:576px) {
          padding: 0 15px;
        }

        @media (min-width:576px) {
          width: 50%;
          padding-right: 2.9375rem;
          padding-left: 15px;
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 12;
        }
      }
      
      .news-item__content {
        padding-left: 0;

        .title {
          margin: 0.75rem 0; 

          @media (min-width: $bp-sm) {
            margin-top: 0;
          }
          @media (min-width: $bp-lg) {
            font-size: 1.75rem;
          } 
          @media (min-width: $bp-xl) {
            font-size: 2rem;
          } 
        }

        .excerpt {
          @media (min-width: $bp-lg) {
            font-size: 1rem;
          } 

          @media (min-width: $bp-xxl) {
            @include line-clamp(4);
          }
        }
      }

    }

    // FEATURED ITEM
    &.featured {
      position: relative;

      .news-item__image {
        width: 100%;
        height: 100%;
        position: relative;

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background: linear-gradient(0deg, #000, transparent);
        }

      }

      .news-item__content {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
        padding: 0 0.5rem;
        
        a {
          display: block;
          text-decoration: none;
          color: #fff;
        }

        .title {
          @media (min-width: $bp-lg) {
            font-size: 1.25rem;
          }
        }

        .news-item__footer {
          padding-right: 0;
          border-bottom: initial;
          border-right: initial;
          border-bottom-right-radius: initial;
  
          &::after {
            content: none;
          }

          .featured__timestamp__text {
            color: #fff;
            font-size: 0.7rem;
            margin-left: 0.25em;
          }

        }
        
      }

      .favourite-btn {
        color: #fff;

        &.filled {
          svg {
            fill: #fff;
          }
        }
      }

    }

    // NO IMAGE
    &.no-image {
      background-color: rgba($primary-colour, 0.07);

      .news-item__content {
        width: 100%;
        padding: 0 0.5rem;

        .title {
          margin: 0.75rem 0;
        }

        .excerpt {
          @media (min-width: $bp-lg) {
            @include line-clamp(6);
          }
        }
      }

      .news-item__footer  {
        border-right: initial;
        border-bottom-right-radius: initial;

        &::after {
          content: none;
        }

        .timestamp {
          padding-left: 0.5rem;
        }
      }

    }

    // HOVERED STATE
    &.hovered {

      // FEATURED ITEM
      &.featured {
        .title {
          color: #fff;
        }
      }
      
    }

  }

}
// CONTACTS

$user-image-size: 60px;

.company-directory {
  .company-directory-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    padding-bottom: 5px;
    // Flick height = 75%;
    @include border-flick();

    .company-directory-item__image {
      width: $user-image-size;
      height: $user-image-size;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: initial;
      font-size: 1.2rem;
      font-weight: 400;
      text-decoration: none;
      color: #fff;
      background-color: $gradient-orange;
      opacity: 1;
      transition: opacity 250ms ease-in-out;

      svg {
        width: $user-image-size / 2;
        height: $user-image-size / 2;
      }
    }

    .company-directory-item__footer {
      margin-top: auto;
      padding-left: 15px;
      padding-bottom: initial;
      padding-top: initial;
      border-bottom: initial;
    }

    &.hovered {
      .company-directory-item__image {
        opacity: 0.75;
        transition: opacity 250ms ease-in-out;
      }
    }
  }
}

.presentations {
  .page-header {
    margin-bottom: 3rem;

    .title-container {
      border-bottom: 1px solid #d2d0cf;

      .presentations-edit-labels {
        &,
        .MuiFormControlLabel-label {
          font-size: 14px;
          color: #333;
          font-weight: 400;
        }
      }
    }
    .item-counter-container{
      margin-bottom: 4px;
      padding-left: 28px;

      .item-counter-logo{
        width: 30px;
        height: auto;
        padding: 0;
      }

      .item-counter{
        font-size: 13px;
        line-height: 21px;
        width: auto;
        display: inline-block;
      }
    }

    #title-label {
      &[data-shrink="false"] {
        color: rgba(0, 0, 0, 0.87);
      }

      &[data-shrink="true"] {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &.creator {
    .filter-container {
      // background-color: #edf7fe;

      @media (min-width: 1400px) {
        .filter-item {
          width: 14.28%;
          flex: 0 0 auto;
          margin-bottom: 0 !important;
        }
      }
    }

    .page-header {
    }
  }

  .filter-container {
    margin: 1rem 0;
    // border-radius: 4px;
    // border: 1px solid #e9e9e9;
    padding: 15px 0;
  }

  h1 {
    font-size: 1.8rem;
  }

  a {
    color: $normal-font-colour;

    input {
      cursor: pointer;
    }
  }

  .btn {
    button {
      text-transform: none;
    }
  }
}

.general-filters {
  .title-textfield {
    & label.Mui-focused {
      color: black;
    }
    & .MuiInput-underline:before {
      border-bottom-color: #d2d0cf;
    }
    & .MuiInput-underline:after {
      border-bottom-color: #d2d0cf;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #d2d0cf;
      }
    }

    .title-clear-btn{
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
      margin-right: 8px;
    }

    .title-adornment-icon{
      width: 20px;
      height: 20px;
      &.search{
        margin-bottom: 5px;
        margin-right: 8px;
      }
    }
  }
}

.add-library {
  .filter-container {
    @media (min-width: 1400px) {
      .filter-item {
        margin-bottom: 1rem !important;
      }
    }
  }
}

.filter-nested {
  .MuiBox-root {
    align-items: center;
  }

  .MuiSvgIcon-root {
    font-size: 20px;
    margin-left: 5px;
  }

  .MuiTypography-root {
    font-size: 16px;
  }
}

.settings-nested {
  .MuiBox-root {
    align-items: center;
  }

  .MuiSvgIcon-root {
    font-size: 20px;
    margin-left: 5px;
  }

  .MuiTypography-root {
    font-size: 16px;
  }
}

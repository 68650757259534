.slide-view-item {
  @include transition(all .25s ease-in-out);

  .mui-card {
    .content,
    .thumb,
    .play-container,
    .skeleton {
      @include transition(all .25s ease-in-out);

      .right {
        @include transition(all .25s ease-in-out);
      }
    }

    .content {
      padding-left: 0;
      padding-right: 0;
    }

    box-shadow: none;
  }

  &.in-active {
    .mui-card {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.87);
      -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-radius: 0px;
      box-shadow: none;
      overflow: hidden;
      width: 100%;

      .content {
        // prevent multiline change issue on active / in-active... this is the padding value
        // margin-left: -16px;
        // margin-right: 16px;

        @include transition(all .25s ease-in-out);
      }

      .media {
        border-radius: 4px;

        img,
        .play-container,
        .skeleton {
          border-radius: 4px;

          .right {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }

  .media {
    position: relative;
  }

  .thumb {
    @include transition(all .25s ease-in-out);
  }
  
  .play-container,
  .select-container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    z-index: 2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    @include transition(all .25s ease-in-out);

    svg {
      fill: white;
    }

    &.select-container {
      background-color: transparent;
    }

    &.selected {
      opacity: 1;
      @include transition(all .5s ease-in-out);
    }

    .selected-icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 24px;

      .icon,
      .tri {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 24px;
        height: 24px;
      }

      .icon {
        z-index: 2;
      }

      .tri {
        z-index: 1;
        width: 0;
        height: 0;
        border-width: 20px;
        border-color: #4caf50 #4caf50 transparent transparent;
        border-style: solid;
        border-top-right-radius: 3px;
      }
    }
  }

  .title {
    color: $dark-font-colour;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-size: 0.85rem;
    line-height: 1.6;
    overflow: hidden;
  }
}
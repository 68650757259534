.edit-profile {
  h1 {
    color: $ps-dark;
  }

  label {
    margin-bottom: 5px;
    color: $ps-brand;
    font-size: 16px;
    font-weight: 500;
  }

  span.value{
    font-size: 16px;
    line-height: 24px;
    color: #415262;
    margin-bottom: 5px;
  }

  input {
    font-weight: 400;
    font-size: 16px;
    color: $ps-body-text-color;
  }

  input::placeholder {
    font-weight: 300;
    color: #acb3b9;
  }

  input::-webkit-input-placeholder {
    font-weight: 300;
    color: #acb3b9;
  }

  input::-moz-placeholder {
    font-weight: 300;
    color: #acb3b9;
  }
  
  input::-ms-input-placeholder {
    font-weight: 300;
    color: #acb3b9;
  }

  .form-control {
    &:focus {
      box-shadow: 0 0 0 1px $ps-brand;
      border-color: $ps-brand !important;
      // border-width: 2px;
    }

    &:hover {
      border-color: #000000;
    }
  }

  .MuiInputBase-root {
    .MuiSelect-select {
      font-size: 16px;
      font-weight: 400;
      color: $ps-body-text-color;
    }
  }
}

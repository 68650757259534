// FORUMS
$icon-size: 24px;

.forums {

  /* Forum item
  ======================================== */
  .forum-item {
    margin-bottom: 30px;
    padding-bottom: 10px;
    // Flick height = 75%;
    @include border-flick(auto) {
      bottom: 10px;
    }
    
    a {
      color: $primary-colour;

      &:hover {
        text-decoration: none;
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }

    .forum-item__icon {
      width: $icon-size;
      display: flex;
      color: $primary-colour;
      @include transition-colour();
    }

    .forum-item__title-container {
      width: 100%;
      display: block;
      padding: 0 15px;
      text-align: inherit;

      .title {
        width: 100%;
        display: flex;
        margin: 0;
        font-weight: 400;
        font-size: 1rem;
        @include transition-colour();
      }

      .title__topics {
        display: flex;
        align-items: center;
        margin-left: auto;
        font-weight: 300;
        font-size: 1rem;
        color: $dark-font-colour;

        @media (min-width: $bp-lg) {
          font-size: 0.8rem;
        }

        .icon {
          width: 20px;
          display: flex;
          margin-right: 0.25rem;
          color: $primary-colour;
        }

        strong {
          width: 0.8rem; // allow for 2 digits
          text-align: right;
          margin-left: 0.25rem;
        }
      }
    }

    button {
      &.add-topic {
        margin: 0 1rem;
        font-size: 0.8rem;
        color: $primary-colour;

        .add-topic__icon {
          width: $icon-size;
          display: flex;
          margin-right: 0.25rem;
        }
      }

      &.chevron,
      &.favourite-btn {
        svg {
          // same as screenItem.scss
          width: 1.25rem;
          min-width: 1.25rem;
          height: auto;
  
          @media (min-width: $bp-lg) {
            width: 1rem;
            min-width: 1rem;
          }
        }
      }

      &.chevron {
        color: $accent-colour;
        @include transformY-onHover();
      }
    }

    /* Excerpt
    ======================================== */
    .excerpt {
      padding-left: calc(#{$icon-size} + 15px);
      font-size: 0.8rem;
      font-weight: 300;
    }

    /* Content
    ======================================== */
    .forum-item__content {
      margin-top: 0.5rem;
      padding: 1rem calc(#{$icon-size} + 15px) 0 calc(#{$icon-size} + 15px);

      .excerpt {
        @include line-clamp(10); // unset default in _screenItem.scss
        margin-bottom: 1rem;
      }
    }

    /* Hovered state
    ======================================== */
    &.hovered {
      .forum-item__icon {
        @include transition-colour();
        color: $secondary-colour;
      }
    }
  }
}

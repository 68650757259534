.circle-picker {
  width: 100% !important;

  [title]:not([title=""]) {
    border: 2px solid $border-colour;
    border-radius: 100%;
  }
}

.colorpicker-container {
  .color-group-label {
    font-weight: 400;
    font-size: 18px;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .color-item {
    border: 4px solid white;
    border-radius: 50%;
    padding: 0px;
    margin: 5px;
    transition: all 200ms;

    &:hover {
      transform: scale(1.1);
      transition: all 100ms ease-in-out;
    }

    &.selected {
      border: 4px solid #1d9bf1;
      padding: 5px;
      margin: 0px;
    }

    .color-btn {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      outline: none;
      border: 1px solid #959394;
      transition: all 100ms;
      position: relative;

      &.none:after {
        content: "";
        position: absolute;
        border-top: 2px solid red;
        width: 16px;
        transform: rotate(45deg);
        transform-origin: 40% 160%;
        left: -1px;
        top: 5px;
      }
    }
  }

  .colors-container.primary {
    border-right: 2px solid #959394;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
}
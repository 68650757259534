// ALL DIALOG MODALS

.alert-modal {
  max-width: 450px;

  @media (min-width: $bp-lg) {
    font-size: 0.8rem;
  }

  .modal-content {
    padding: 1.5rem;
  
    .modal-header,
    .modal-footer {
      padding: 0;
      border: initial;
    }
  
    .modal-header {
      .icon {
        display: flex;
        align-items: center;
        height: calc(1.25rem * 1.5); // line height of title
        margin-right: 0.5rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;  
        }
      }
    }
  
    .modal-body {
      padding: 1rem 0;
    }

    button {
      font-size: inherit;
    }

    .close-modal-btn {
      width: 6.25rem; // match 'cancel' & 'ok size
    }
  }

  // Warning modal
  &.alert-modal--warning {
    .close-modal-btn {
      color: #fff;
    }
  }

}
/**
 * Plugin styles
 */
 .slide-title {
  padding: 0;
  margin: 0;
  outline: none;
  cursor: text;
}

.slide-title p,
.slide-title div {
  padding: 0 !important;
  margin: 0 !important;
}

.slide-title[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.slide-title[data-placeholder]:empty::before {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}
.page-container {
  &[data-page="view-presentations"],
  &[data-page="preview-presentations"] {
    margin-top: 0;
  }

  div[data-page="edit-presentations"],
  div[data-page="view-presentations"],
  div[data-page="preview-presentations"] {
    margin: 0;
    padding: 0;
    height: 100%;

    .reveal-loader {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.9);
      z-index: 999;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }

  .slide-wrap {
    position: relative;
  }
}
$height: 111px;

.settings-wrap-lg-main {
  .layouts {
    .main-wrapper {
      width: 100%;

      .inner-wrapper,
      .pagination {
        width: 100%;
        white-space: nowrap;
        height: 200px;
        overflow: auto;
        padding: 2em 1em;

        &.pagination {
          height: auto;
          padding: 0.5em;
        }

        .layout-wrap,
        .pagination-item,
        .scheme-wrap,
        .position-wrap {
          display: inline-block;
          transform-origin: center;
          transform: scale(1);
          margin-right: 1.5em;
          border-radius: 5px;
          overflow: hidden;
          transition: transform 100ms ease 0s;
          height: $height;
          width: $height / 9 * 16;

          &.scheme-wrap,
          &.position-wrap {
            height: $height / 2;
            width: auto;
            min-width: 100px;

            button {
              height: $height / 2;
              line-height: $height / 2;
              width: auto;
              min-width: 100px;
              text-transform: capitalize;
            }
          }

          &:hover,
          &.selected {
            transform: scale(1.1);
            box-shadow: 0px 0px 5px 1px $primary-colour;
          }

          &.no-lg {
            button {
              padding: 0;
            }
          }

          button {
            @include btn-reset();
            height: $height;
            width: $height / 9 * 16;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;

            span {
              width: 100%;
              color: $light-font-colour;
              white-space: normal;
            }
          }
        }
      }
    }
  }

  .form-control {
    font-size: 0.8em;
    font-family: monospace;
  }
}
.inner-container {
  width: 100%;
  height: 100%;
}

.overlay-container{
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  overflow: hidden;
  z-index: 2;
}
// fab button
.create-pres {
  margin-bottom: 8px;
  
  .fab {
    transition: all 0.1s ease-in-out;

    &.active {
      transform: rotate(45deg);
      transition: all 0.1s ease-in-out;
    }
  }

  #create-presentation-button {
    min-width: 22px;
    width: 22px;
    height: 22px;
    min-height: 22px;
    margin-right: 10px;
    display: flex;

    svg {
      width: 20px;
      height: auto;
    }
  }
}

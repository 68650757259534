.audio-prompt-dialog {
  .MuiPaper-root {
    background-color: #222;
    border: 1px solid #444;
    border-radius: 25px;
    color: #fff;
  }

  .audio-prompt-title {
    text-align: center;
  }

  .audio-prompt-content {
    .audio-prompt-content-row {
      justify-content: center;

      .audio-prompt-content-col {
        display: flex;
        justify-content: start;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;

        &.dismiss {
          justify-content: center;

          .dismiss-btn {
            text-transform: none;
            background-color: #bababa;
            color: #222;
            transition: all 0.5s ease;

            &:hover {
              background-color: #fff;
              transition: all 0.5s ease;
            }
          }
        }
      }

      .icon {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
}

$text-color: #f9f9f9;
$branding: white;

.plyr {
  button {
    border-radius: 50px !important;
  }
}

.plyr__controls,
.plyr__controls__item {
  color: $branding !important;

  svg {
    fill: $branding !important;
  }

  &[type="button"] {
    &:hover {
      background-color: #1d9bf1 !important;
    } 
  }
}

.plyr--full-ui input[type="range"] {
  color: $branding !important;
}

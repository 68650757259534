// SEARCH

.screen {
  &.search {
    .page-header {
      h1 {
        span {
          font-weight: 400;
          color: $primary-colour;
        }
      }
    }

    .search-content {
      max-width: 920px;

      .search-content__title {
        margin-bottom: 1.5rem;
        color: $primary-colour;
      }
    }

    .result-item {
      display: block;
      margin-bottom: 1rem;
      text-decoration: none;

      P {
        margin-bottom: 0;
      }

      svg {
        display: block;
      }

      .result-item__content-type {
        color: $secondary-colour;

        p {
          font-weight: 400;
          line-height: 1.2;
        }

        svg {
          width: 0.8rem;
          height: auto;
          margin-right: 0.25rem;
        }
      }

      .result-item__title {
        p {
          font-weight: 400;
          color: $dark-font-colour;
        }

        svg {
          color: $primary-colour;
        }
      }

      &:hover {
        .result-item__title {
          p {
            color: $primary-colour;
            transition: color 250ms ease;
          }
        }
      }
    }
  }
}

// GALLERIES

.galleries {
  .galleries-item {
    .galleries-item__image {
      width: 100%;
      height: 100%;

      &::after {
        content: "";
        width: 100%;
        height: 50%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, #000, transparent);
      }
    }

    .galleries-item__content {
      position: absolute;
      bottom: 0;
      padding: 15px;
      left: 0;
      right: 50px;
      color: #fff;

      .title {
        @media (min-width: $bp-lg) {
          font-size: 1.25rem;
        }
      }
    }

    .favourite-btn {
      position: absolute;
      bottom: 10px;
      right: 10px;
      color: #fff;
      z-index: 1;

      &.filled {
        svg {
          fill: #fff;
        }
      }
    }

    .galleries-item__footer {
      padding: 0.5rem;
      @include border-flick();

      .author {
        display: flex;
        align-items: center;

        .author__image {
          width: 30px;
          height: 30px;
        }

        .author__name {
          margin-left: 5px;
          font-size: 0.8rem;
        }
      }
    }

    &.no-image {
      .galleries-item__image {
        border: initial;
        background-color: rgba($primary-colour, 0.4);
      }
    }

    // HOVERED STATE
    &.hovered {
      .title {
        color: #fff;
      }
    }
  }
}

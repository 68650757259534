// QUIZ SINGLE

$checkbox-size: 1.5rem;
$checkbox-size-lg: 1rem;

.single {
  &.quiz {
    .article {
      .article__body,
      .section__wrapper {
        margin-bottom: 1.5rem;
        border-bottom: 1px solid $border-colour;
      }

      // Quiz section
      .section__wrapper {
        padding-bottom: 1rem;

        .section__question {
          margin-bottom: 1rem;
        }

        // Quiz item
        .section__answer {
          margin-bottom: 0.5rem;
          padding: 0 1.5rem;

          button {
            @include btn-reset();
            display: flex;
            height: calc(1.5 * 1rem); // same line height as label

            svg {
              width: $checkbox-size;
              height: 100%;
              color: $normal-font-colour;

              @media (min-width: $bp-lg) {
                width: $checkbox-size-lg;
              }
            }

            &:hover {
              opacity: 0.8;
            }
          }

          label {
            margin: 0;
            font-weight: 400;
            color: $primary-colour;
          }

          .selected__icon,
          .selected__answer {
            color: $green;
          }

          .unselectable__icon,
          .unselectable__answer {
            opacity: 0.5;
          }
        }
      }

      .section__submission__notice {
        margin-bottom: 2rem;
      }

      // Quiz submit
      .section__button__wrapper {
        display: flex;
        justify-content: flex-end;

        svg {
          margin-left: 0.5rem;
        }
      }

      // Submitted confirmation
      .submitted__container {
        display: flex;
        align-items: flex-start;
        margin: 2rem 0;

        svg {
          min-width: calc(1.5 * 1rem);
          width: calc(1.5 * 1rem);
          height: calc(1.5 * 1rem); // same line height as text
          margin-right: 0.5rem;
        }
      }
    }
  }
}

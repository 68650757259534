// MESSAGES PAGE

.messages {
  height: calc(100vh - #{$header-height});
  display: flex;
  flex-direction: column;
  padding: 0; // safari 100vh bug fix

  // Only target safari for 100vh bug fix
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  @media (min-width: $bp-lg) {
    height: calc(100vh - #{$header-height-xl});
  }

  @media (min-width: $bp-xl) {
    height: calc(100vh - #{$header-height-xl});
  }

  .content-wrapper {
    flex: 1;
    overflow: hidden;
  }

  .message-list-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    @include custom-scrollbar();

    .page-header {
      margin-bottom: calc(24px + 0.25rem);
      padding-top: ($page-margin / 1.25); // same as default screen.scss
    }

    .message-list {
      flex: 1;
      overflow: auto;

      @media (min-width: $bp-lg) {
        margin-bottom: 1.5rem;
      }
    }
  }

  .message-thread-container {
    width: 100%;
    height: 100%;
    padding-left: 1.5rem;

    @media (min-width: $bp-xxl) {
      padding-left: 2rem;
    }

    // Conversations (thread) page header (desktop only)
    .conversation {
      height: 100%;
      padding: 0;

      .conversation__header {
        margin-top: 3rem;
      }
    }
  }
}

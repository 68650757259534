// EVENTS ITEM

$gutter-size: 15px;
$border-width: 4px;

.events {
  .event-item {

    .item__link {
      pointer-events: auto;
    }

    .date {
      width: 76px;
      height: 76px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // padding-left: $gutter-size;
      // padding-right: $gutter-size;
      position: relative;
      background-color: rgba($primary-colour, 0.1);
      border-radius: 15px;
      color: $primary-colour;
      text-decoration: none;
      overflow: hidden;

      @media (min-width: $bp-sm) {
        width: 90px;
        height: 90px;
      }

      h3 {
        margin: 0;
        line-height: 1;
      }

      // Hyphen (multi-day)
      span {
        margin: 0 0.125rem;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1;
      }

      .date__day,
      .date__month {
        display: flex;
        justify-content: center;
      }

      .date__month {
        display: flex;
        align-items: center;
        padding: 0.35rem 0.5rem;
        color: $white;
        background-color: $primary-colour;

        h3 {
          font-size: 0.7rem;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          color: $white;
        }
      }

      .date__day {
        flex: 1;
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        h3 {
          font-size: 2.3rem;
        }

        // Multi-day event
        &.multi-day {
          flex-direction: column;

          h3 {
            font-size: 1rem;
            text-transform: uppercase;

            &:first-of-type {
              margin-bottom: 0.25rem;
            }
          }
        }
      }
    }

    .event-item__image {
      width: 100%;
      max-width: 160px;
      margin-left: $gutter-size;
      display: none;

      @media (min-width: $bp-sm) {
        display: block;
      }

      @media (min-width: $bp-lg) {
        display: none;
      }

      @media (min-width: $bp-xxl) {
        display: block;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .event-item__content {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.75rem;
      padding-left: $gutter-size;

      @media (min-width: $bp-lg) {
        margin-bottom: 0;
      }

      .title {
        margin: 0;
      }
    }

    .event-item__footer {
      margin-top: auto;
      margin-left: $gutter-size;
      padding: 0 0 5px 0;
      // Flick height = 75%;
      @include border-flick();

      .category__text {
        @include line-clamp(1);
      }
    }

    &.no-image {
      .event-item__image {
        border: initial;
        background-color: rgba($primary-colour, 0.4);
      }

      .event-item__content {
        // padding-left: 0;
        padding-right: 15px;
      }
    }
  }
}

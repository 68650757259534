$thumb-size: 60px;

.transitions {
  .col {
    width: 60px;
    overflow: hidden;
    text-align: center;
    cursor: pointer;

    button {
      width: 60px;
      overflow: hidden;
      min-width: 0;
      border-radius: 0;
    }

    span {
      text-transform: capitalize;
      font-size: 12px;
      display: block;
      margin-top: 2px;
    }

    .ts-1 {
      background-color: #ddd;
    }

    &.transition-none {
      .t-slide {
        @include transition(none);
      }
    }

    .t-slide {
      @include transition(all 0.5s ease-in-out);
    }

    &:hover {
      .ts-1 {
        left: -$thumb-size;
      }

      .ts-2 {
        left: 0;
      }
    }

    &.transition-slide {
      &:hover {
        .ts-1 {
          left: -$thumb-size;
          @include transition(all 0.5s ease-in-out);
        }

        .ts-2 {
          left: 0;
        }
      }
    }

    &.transition-fade {
      .t-slide {
        left: 0;

        &.ts-2 {
          opacity: 0;
        }
      }

      &:hover {
        .ts-1 {
          opacity: 0;
        }

        .ts-2 {
          opacity: 1;
        }
      }
    }

    &.transition-convex {
      .ts-1 {
        transform: translateX(-52%) rotateY(-45deg);
        transform-origin: 100% 50%;
        left: $thumb-size / 4;
      }

      .ts-2 {
        transform: translateX(52%) rotateY(45deg);
        transform-origin: 0% 50%;
        left: $thumb-size / 4;
      }

      &:hover {
        .ts-1 {
          transform: translateX(-104%) rotateY(-75deg);
          left: 0;
        }

        .ts-2 {
          transform: translateX(0%) rotateY(0);
          left: 0;
        }
      }
    }

    &.transition-concave {
      .ts-1 {
        transform: translateX(-33%) rotateY(65deg);
        transform-origin: center;
        left: 0;
      }

      .ts-2 {
        transform: translateX(-33%) rotateY(-65deg);
        transform-origin: center;
        left: 30px;
      }

      &:hover {
        .ts-1 {
          transform: translateX(-60%) rotateY(90deg);
        }

        .ts-2 {
          transform: translateX(0%) rotateY(0deg);
          left: 0;
        }
      }
    }

    &.transition-zoom {
      .t-slide {
        left: 0;

        &.ts-2 {
          opacity: 0;
          transform: scale(0.5);
        }
      }

      &:hover {
        .ts-1 {
          opacity: 0;
          transform: scale(3);
        }

        .ts-2 {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  .btn {
    @include btn-reset();
    width: 60px;
    height: 34px;
    position: relative;
    overflow: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 400px;
    perspective: 400px;
    border-radius: 0;
    margin-bottom: 10px;
  }

  .transition {
    margin: 0 26px;
  }

  .selected {
    box-shadow: 0 0 0 3px #1d9bf1;
  }

  .t-slide {
    width: 60px;
    height: 34px;
    line-height: 34px;
    background-color: #cfcfcf;
    display: block;
    background-color: #eee;
    color: #000;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;

    &.ts-2 {
      left: $thumb-size;
    }
  }
}

$row-height: 40px;

.ps-toolbar {
  height: $row-height * 2;
  padding-top: 1px;
  width: 100%;
  background-color: $ps-light;
  border-bottom: 1px solid lighten($border-colour, 5%);

  .container-fluid {
    height: $row-height * 2;

    .first-row {
      .ls,
      .rs {
        padding-top: 5px;
      }
    }

    .row {
      height: $row-height;
    }

    .ls {
      height: 100%;

      .row {
        padding-left: 15px;
      }
  
      .col-auto {
        padding-left: 0;
        padding-right: 0;
        margin-right: 20px;
        height: $row-height;
        line-height: $row-height;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ps-title {
        color: $ps-dark;
        font-size: 20px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        height: $row-height;
        -webkit-line-clamp: 1;
        white-space: nowrap;
        line-height: $row-height;
      }
    
      .MuiIconButton-root {
        background-color: #1d9bf1;
        padding: 4px;
        // opacity: 0.85;
    
        svg {
          font-size: 18px;
        }
    
        &:hover {
          background-color: #1d9bf1;
          opacity: 1;
        }
      }
    
      .preview-btn {
        color: $ps-dark;
        font-size: 16px;
        text-transform: none;
        padding-left: 5px;
    
        .MuiSvgIcon-root  {
          background-color: #1d9bf1;
          padding: 4px;
          border-radius: 50%;
          font-size: 26px;
          color: white;
      
          &:hover {
            background-color: #1d9bf1;
          }
        }
      }
    }

    .rs {
      height: $row-height;

      .row {
        padding-right: 15px;
      }

      .col-auto {
        height: $row-height;
        line-height: $row-height;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
      }
  
      .bx {
        margin-left: 5px;

        button {
          padding-bottom: 0;
          padding-top: 0;
          &.delete-button{
            &:hover{
              background-color: #bf1e2c;
              color: white;
            }
          }
          &:hover {
            background-color: $ps-brand;
            color: white;
          }
        }

        &.checkbox {
          // border: 1px solid $border-colour;
          padding: 3px 10px;
          border-radius: 7px;
          display: flex;
          justify-content: center;
          align-items: center;

          .MuiCheckbox-root {
            padding: 0 5px;
            margin-top: -1px;
          }

          .MuiSvgIcon-fontSizeSmall {
            font-size: 22px;
            margin-top: 3px;
          }

          .MuiFormControlLabel-label {
            font-size: 16px;
            color: $ps-dark;
            margin-top: 3px;
          }
        }
      }

      button {
        font-size: 16px;
        // padding-left: 0;
        // padding-right: 0;
        min-width: auto;
        color: $ps-dark;
      }

      .MuiFormControlLabel-root {
        margin-right: 0 !important;

        svg {
          &.MuiSvgIcon-fontSizeSmall {
            font-size: 16px !important;
          }
        }
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }  
}
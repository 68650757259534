// All list pages (items common style)

.screen {
  a:link { text-decoration: none; }
  a:visited { text-decoration: none; }
  a:hover { text-decoration: none; }
  a:active { text-decoration: none; }
  
  .item {
    width: 100%;
    margin-bottom: 30px;

    // IMAGE
    .item__image {
      display: block;
      border: 1px solid lighten($border-colour, 5%);
      overflow: hidden;

      img {
        height: 100%;
        object-fit: cover;
        @include transition-scale(1);
      }
    }

    // CONTENT
    .item__content {
      display: block;
      padding-left: 15px;
      text-decoration: none;
      color: inherit;

      .title {
        @include line-clamp(2);
        @include transition-colour();
        font-size: 1.25rem;
        line-height: 1.3;

        @media (min-width: $bp-lg) {
          font-size: inherit;
        }
      }

      .excerpt {
        @include line-clamp(2);
        font-size: 1rem;

        @media (min-width: $bp-lg) {
          @include line-clamp(2);
          font-size: 0.8rem;
        }

        @media (min-width: $bp-xxl) {
          @include line-clamp(3);
        }
      }
      
    }

    // FOOTER
    .item__footer {
      margin-top: 0.75rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $border-colour;

      svg {
        width: 1.25rem;
        min-width: 1.25rem;
        height: auto;

        @media (min-width: $bp-lg) {
          width: 1rem;
          min-width: 1rem;
        }
      }

      .category,
      .timestamp {
        display: flex;
        align-items: center;
        color: $primary-colour;
        padding-right: 0.5rem;
      }
      .px-2{
        padding-right: 1rem !important;
        padding-left: 0px !important;
      }
      .timestamp {

        .timestamp__text {
          margin-left: 0.25em;
          font-size: 0.8rem;

          @media (min-width: $bp-lg) {
            font-size: 0.7rem;
          }
        }
      }

      .category {

        .category__text {
          margin-left: 0.25em;
          font-size: 0.8rem;
        }

      }

    }

    .favourite-btn {
      @include btn-reset();
      @include transformY-onHover();
      display: flex;
      color: #4a4b4d;

      &.filled {
        svg {
          fill: #4a4b4d;
        }
      }
    }

    // HOVERED STATE
    &.hovered {
      .title {
        @include transition-colour();
        // color: $secondary-colour;
      }

      .item__image {
        img {
          @include transition-scale(1.1);
        }
      }

    }

  }

}
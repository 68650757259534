// MESSAGE FORM (used in messages)

$focus-border-size: 3px;

.message-form-container {
  margin-bottom: 1.5rem;
  padding-right: $focus-border-size; // prevent box-shadow focus being cut off
  padding-left: $focus-border-size; // prevent box-shadow focus being cut off

  .message-form {
    padding: 0.5rem 1rem;
    border: 1px solid $border-colour;
    border-radius: 5px;

    &:focus-within {
      border-color: $primary-colour;
      border-color: transparent;
      box-shadow: 0 0 0 $focus-border-size rgba($primary-colour, 0.4);
    }

    .message-form__input {
      width: 100%;
      padding: 0;
      border: initial;
      outline: none;
      resize: none;
      font-weight: inherit;

      &:focus {
        box-shadow: initial;
      }
    }

    .submit {
      @include btn-reset();
      height: calc(0.9rem * 1.5);
      display: flex;
      align-items: center;
      color: $primary-colour;

      svg {
        display: block;
      }

      &:hover {
        color: $accent-colour;
      }
    }
  }
}
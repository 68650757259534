// vars
$modal-padding: 20px 40px; // default MUI <DialogContent /> padding
$col-padding: 60px;

.ps-modal,
.ss-modal,
.add-in-dialog {
  color: $ps-body-text-color;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    color: $ps-body-text-color;
    font-size: 16px;
  }

  // resets
  .MuiDialogContent-root {
    padding: 0;
  }

  .spacer {
    &.ls {
      padding-right: calc($col-padding / 2);
    }

    &.rs {
      padding-left: calc($col-padding / 2);
    }
  }

  .ps-modal-title,
  .ss-modal-title {
    height: 80px;
    background-color: $ps-light;

    .container,
    .container-fluid {
      height: 100%;
      padding: $modal-padding;

      .row {
        height: 100%;
      }
    }

    h3 {
      font-size: 30px;
      color: $ps-dark;
      margin: 0;
    }
  }

  .ps-modal-content,
  .ss-modal-content {
    &.ps-modal-content {
      .container,
      .container-fluid {
        padding: $modal-padding;
      }
    }

    .ss-subtitle {
      font-size: 24px;
      color: $ps-dark;
    }

    .ss-subtitle-color {
      color: $ps-dark;
    }

    label,
    span {
      font-size: 16px;

      &.help-text {
        font-size: 12px;
      }
    }

    label {
      color: $ps-brand;
      font-weight: 500;
    }

    span {
      color: $ps-body-text-color;

      &.MuiCircularProgress-root {
        color: $ps-brand;
      }
    }

    // .slider{
    //   span{
    //     color: #fff !important;
    //   }
    // }

    .slide-settings-select {
      .MuiSelect-select {
        font-size: 16px;
        color: #415262;
        font-weight: 300;
      }
    }

    .add-in-url {
      text-transform: none;
      font-size: 13px;

      span {
        color: inherit !important;
      }
    }
  }

  // buttons
  .MuiButton-root {
    font-size: 14px;
    min-width: 100px;
    text-transform: none;
    margin-right: 10px;

    &.cancel {
      background-color: #fff;
    }
  }

  .preset-btn,
  .new-btn {
    margin: 0;
  }

  .adv-presentation-settings-sub-container {
    position: relative;
    justify-content: start;
    align-items: center;

    .hint {
      max-width: 90%;
      text-align: center;
      margin-top: 15px;

      span {
        line-height: 1.4;
        font-weight: 400;
      }
    }

    .media-lib-btn-container {
      position: absolute;
      margin: auto 0;
      top: 0;
      bottom: 0;
      align-items: center;
    }
  }

  .form-group {
    margin-bottom: 15px;

    &.multi-group {
      display: flex;
      align-items: center;
    }

    .inner-form-group {
      float: right;
    }

    label {
      margin-bottom: 5px;
      color: $ps-brand;
      font-size: 16px;
      font-weight: 500;
    }

    input,
    textarea {
      font-size: 16px;
      color: $ps-body-text-color;
    }

    .inline-action {
      text-transform: none;
      font-size: 14px;
    }

    .MuiFormControl-root {
      // min-height: 46px;
      // justify-content: center;
    }

    .MuiInputBase-root {
      .MuiSelect-select {
        font-size: 16px;
        font-weight: 400;
        color: $ps-body-text-color;
      }
    }

    .MuiFormControlLabel-root {
      // min-height: 46px;

      &.dark {
        .MuiFormControlLabel-label {
          color: $ps-body-text-color;
        }
      }

      .MuiFormControlLabel-label {
        color: $ps-brand;
        font-size: 16px;
        font-weight: 500;
      }

      .MuiCheckbox-root {
        padding-top: 0;
        padding-bottom: 0;
      }

      svg {
        font-size: 22px;
      }
    }
  }

  // tables
  .MuiTable-root {
    table-layout: fixed;

    .MuiTableCell-root {
      .MuiCheckbox-root {
        padding: 0;
      }
    }
  }

  // textarea
  .MuiInputBase-multiline {
    padding: 10px;
  }

  .bordered {
    border: 1px solid $border-colour;
    border-radius: 5px;
    padding: 24px;

    table {
      color: $ps-body-text-color;

      th {
        color: $ps-body-text-color;
      }
    }
  }

  // presentation background
  .pres-bg {
    .colorpicker-container {
      margin-bottom: 0 !important;
      border: 1px solid $border-colour;
      padding: 10px;
      border-radius: 4px;

      .colors-container.primary {
        border-right: none;
        padding-right: 0;
      }
    }
  }

  .slide-settings-base {
    .label-container {
      width: 29%;
      flex: 0 0 auto;
    }
  }
}

.add-library-modal {
  .alert-container {
    width: 95%;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;

    .MuiAlert-message {
      font-size: 14px;
    }
  }
}

.alert-dialog {
  .ca-logo {
    width: 50px;
    height: auto;
    margin-right: 20px;
    margin-left: -50px;
  }
  .close-modal-btn {
    text-transform: none;
  }
}

.presentation-library-modal {
  .library-btn {
    width: 100px;
    text-transform: none;
    font-size: 14px;
    margin-left: 20px;

    &.slides {
      width: auto;
      margin-left: 10px;
      text-decoration: underline;
      &.clear {
        color: #415262;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }

  .MuiButtonBase-root {
    text-transform: none;
  }
}

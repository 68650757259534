// FORUM TOPIC COMMENTS

$link-colour: $secondary-colour;

.forum-topic-comments  {

  .page-header {
    margin-bottom: 1rem;
  }

  /* ========================================
    TOPIC HEADER
  ======================================== */
  .comments-header__board {
    margin-bottom: 0.5rem;
    text-decoration: none;
    color: $primary-colour;
    @include transition-colour();

    &:hover {
      color: $secondary-colour;
      @include transition-colour();
    }
  }

  .comments-header__author-image {
    width: 30px;
  }

  .comments-header__author {
    margin-left: 0.5rem;
    font-size: 0.8rem;
    color: $link-colour;
  }
  
  .comments-header__timestamp {
    display: flex;
    align-items: center;
    font-size: 0.8rem;

    &::before {
      content: "";
      display: block;
      width: 3px;
      height: 3px;
      margin: 0 15px;
      border-radius: 50%;
      background-color: $light-font-colour;
    }
  }

  /* ========================================
    COMMENT
  ======================================== */
  .topic-comment {
    display: flex;
    margin-bottom: 1rem;
    border: 1px solid $border-colour;
    border-radius: 10px;
    overflow: hidden;
  }

  .topic-comment__author,
  .topic-comment__content {
    padding: 20px;
  }

  /* Comment: Author
  ======================================== */
  .topic-comment__author {
    flex: 0 0 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid $border-colour;
    background-color: $light-grey;

    a {
      color: $link-colour;
    }

    .user-image {
      width: 80px;
      height: 80px;
      background-color: #fff;

      svg {
        display: block;
        width: 50%;
        height: auto;
      }
    }

    .username {
      display: block;
      margin-top: 0.25rem;
      font-size: 0.8rem;
      text-align: center;
    }

    .user-member-date {
      display: block;
      margin-top: auto;
      font-size: 0.7rem;
    }

    .author-flag {
      display: block;
    }
  }

  /* Comment: Content
  ======================================== */
  .topic-comment__content {
    flex: 1;
  }

  /* Comment: Header
  ======================================== */
  .topic-comment__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .comment-created {
      font-size: 0.8rem;
      color: $light-font-colour;
    }

    .comment-url {
      width: 20px;
      margin-left: auto;
      margin-right: 1rem;
      color: $primary-colour;
      cursor: pointer;
      position: relative;

      .comment-clipboard {
      }

      svg {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  
    .comment-index {
      font-size: 0.8rem;
      color: $light-font-colour;
    }
  }

  .topic-comment__body {
    font-size: 0.9rem;
  }

  /* Comment: Replied (parent comment)
  ======================================== */
  .replied-comment {
    position: relative;
    margin-bottom: 1em;
    padding: 15px 15px 15px 18px;
    border: 1px solid $primary-colour;
    border-radius: 10px;
    overflow: hidden;
    font-size: 0.8rem;

    &::before {
      content: "";
      width: 3px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $primary-colour;
    }

    .username {
      color: $link-colour;
    }

    .parent-comment-link {
      margin-left: auto;
      color: $link-colour;
    }

    .excerpt {
      font-style: italic;
    }
  }

  .reply-btn {
    @include btn-reset();
    font-size: 0.8rem;
    color: $link-colour;
  }

  /* ========================================
    ADD A COMMENT
  ======================================== */
  .comments-post-container {
    margin-top: 4rem;

    .add-comment-header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      
      @media (min-width: $bp-md) {
        margin-left: 20%;
      }
    }

    .add-comment-header__icon {
      width: 1.75rem;
      height: 1.75rem;
      margin-right: 0.25rem;

      svg {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .add-comment-header__title {
      margin: 0;
    }

    .reset-btn {
      @include btn-reset();
      color: $primary-colour;
    }
  }
}
$icon-size: 75px;

.icon-selector-modal {
  .modal-header {
    position: relative;
    background-color: rgba(30, 156, 241, 0.08);

    .modal-title {
      margin-right: 20px;
    }

    .modal-close-btn {
      @include btn-reset();
      padding: 0;
      width: 50px;
      height: 50px;
      transition: all .2s ease-in-out; 

      &:hover{
        transform: scale(1.1);
      }
    }
  }

  .modal-footer {
    position: relative;
    background-color: rgba(30, 156, 241, 0.08);
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }

  .icon-selector {
    .svg-item {
      .svg-btn {
        @include btn-reset();
        background-color: #e9e9e9;
        margin-bottom: 10px;
        display: block;
        width: $icon-size;
        height: $icon-size;
        line-height: $icon-size;
        border-radius: 1px;
        box-shadow: 0 0 0 1px transparent;

        span {
          display: block;
          width: $icon-size;
          height: $icon-size;
        }
      }

      &.selected {
        .svg-btn {
          box-shadow: 0 0 0 1px;
        }
      }
    }
    
    .alert {
      .btn-close {
        right: 10px;
        bottom: 0;
        margin: auto;
      }
    }
  }
}
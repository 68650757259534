// PUBLIC HOME

.home--public {
  .home-item {
    .home-item__content {
      .title {
        @media (min-width: $bp-sm) {
          @include line-clamp(3);
        }
        @media (min-width: $bp-md) {
          @include line-clamp(4);
        }
      }

      .excerpt {
        @media (min-width: $bp-sm) {
          @include line-clamp(2);
        }
        @media (min-width: $bp-md) {
          @include line-clamp(4);
        }
      }
    }
  }
}
// COMMENT FORM

.comment-form {
  padding: 1rem 1rem 0.5rem;
  border: 1px solid $border-colour;
  border-radius: 5px;

  &:focus-within {
    border-color: $primary-colour;
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba($primary-colour, 0.4);
  }

  .reset-btn {
    position: absolute;
    right: 10px;
    width: 40px;
    height: 40px;
    padding: 0;
  }

  .comment-form__input {
    width: 100%;
    padding: 0;
    border: initial;
    outline: none;
    resize: none;
  }

  .comment-form__footer {
    padding-top: 0.5rem;
    border-top: 1px solid $border-colour;

    .user {
      font-size: 0.8rem;
      color: $secondary-colour;
    }

    .submit {
      @include btn-reset();
      color: $primary-colour;

      &:hover {
        color: $accent-colour;
      }
    }
  }

}


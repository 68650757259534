// ADD TOPIC SCREEN

.screen {
  &.add-forum-topic {
    max-width: 840px;

    .add-forum-topic__header {
      margin-bottom: 2rem;

      .title {
        margin-bottom: 2rem;
      }

      .add-topic-board {
        margin-bottom: 0.5rem;
        text-decoration: none;
        color: $primary-colour;
        @include transition-colour();
  
        &:hover {
          color: $secondary-colour;
        }
      }
    }

    /* ========================================
      TOPIC FORM
    ======================================== */
    .topic-form {
      padding: 1rem;
      border: 1px solid $border-colour;
      border-radius: 5px;
    
      &:focus-within {
        border-color: $primary-colour;
        border-color: transparent;
        box-shadow: 0 0 0 3px rgba($primary-colour, 0.4);
      }
    
      .topic-form__input {
        width: 100%;
        padding: 0;
        border: initial;
        outline: none;
        resize: none;
        font-weight: inherit;
      }
    
      input {
        &.topic-form__input  {
          margin-bottom: 0.5rem;
          padding-bottom: 0.25rem;
          font-size: 1.2rem;
          font-weight: 400;
          border-bottom: 1px solid $border-colour;
    
          &:focus {
            border-bottom: 1px solid transparent;
            box-shadow: 0 2px 0 0 rgba($primary-colour, 0.4);
          }
        }
      }
    
      .topic-form__footer {
        padding-top: 0.5rem;
        border-top: 1px solid $border-colour;
    
        .user {
          font-size: 0.8rem;
          
          a {
            margin-left: 0.25rem;
            color: $secondary-colour;
          }
        }
    
        .submit {
          @include btn-reset();
          color: $primary-colour;
    
          &:hover {
            color: $accent-colour;
          }
        }
      }
    
    }
  }
}

.embed-container {
  position: relative;
  display: inline-block;
  min-width: 50px;

  &.show::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 40%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.embed .cdx-loader {
  min-height: 200px;
}

.embed-settings-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.embed-btn,
.embed-replace-btn {
}

.embed-replace-btn {
}

.embed-replace-btn:hover {
}

.embed-btn svg,
.embed-replace-btn svg {
}

/**
 * Modal
 */
.embed-modal {
  visibility: hidden;
  opacity: 0;
  width: 50%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;


  .modal-title{
    font-weight: 700 !important;
    color: #1d9bf1 !important;
  }
  
}

.embed-modal.show {
  z-index: 999;
  visibility: visible;
  opacity: 1;
}

.embed-editor {
  width: 100%;
  resize: none;
  height: 100%;
  max-height: 100%;
  border: 1px solid rgba(0,0,0, .2);
  outline: none;
  background: transparent;
  margin: 25px auto 0;
  font: 12px / normal "Monaco", "Menlo", "Ubuntu Mono", "Consolas",
    "source-code-pro", monospace;
  flex-grow: 1;
}

.embed-close {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9999;
  opacity: 0.5;
  color: #4f4f4f;
  -webkit-user-select: none;
  user-select: none;
  background: #f5f5f5;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  line-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg{
    width: 20px;
    height: 20px;
  }
}

.embed-close:hover {
  color: #000;
  background: #fff;
  opacity: 1;
}

// DOCUMENTS
$pdf-icon-size: 15px;

.documents {
  .document-item {
    margin-bottom: 20px;
    padding-bottom: 10px;
    // Flick height = 75%;
    @include border-flick();

    .document-item__icon {
      width: $pdf-icon-size;
      display: block;
      color: $pdfIconColour;
    }

    .document-item__content {
      padding: 0 15px;

      .title {
        margin: 0;
        font-weight: 400;
        font-size: 1rem;

        @media (min-width: $bp-lg) {
          font-size: 0.8rem;
        }
      }
    }

    .filesize {
      font-size: 0.8rem;
      color: $light-font-colour;
    }

    button {
      @include btn-reset();

      // same as screenItem.scss
      svg {
        width: 1.25rem;
        min-width: 1.25rem;
        height: auto;

        @media (min-width: $bp-lg) {
          width: 1rem;
          min-width: 1rem;
        }
      }

      &.info {
        color: $accent-colour;
        @include transformY-onHover();
      }

      &.favourite {
        color: $secondary-colour;
      }

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .excerpt {
      margin-left: calc(#{$pdf-icon-size} + 15px);
      font-size: 0.8rem;
      color: $normal-font-colour;
    }
  }
}

$add-slide-size: 24px;
$action-button-height: 80px;
$save-button-height: 75px;

.ps-sidebar {
  width: 260px;
  background-color: #f1f1f1;
  padding: 0;
  margin: 0;
  border-right: 1px solid $border-color;

  .sidebar-container {
    height: 100%;
    position: relative;

    &.overflow {
      padding-bottom: 75px;
    }

    .empty {
      width: 100%;
      padding: 1em;
      text-align: center;
    }

    .thumbnail-wrapper {
      height: calc(100% - $action-button-height);
      overflow: auto;
      border-bottom: 1px solid $border-colour;
      position: relative;
      padding-top: 20px;
    }
  }

  .actions {
    padding: 10px 20px;
    width: 100%;
    height: $action-button-height;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid lighten($border-colour, 5%);
  }

  .slide-thumb-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slide-thumb-index {
    width: 30px;
    margin-left: -15px;
    margin-right: 5px;

    .row {
      text-align: right;
      margin-left: 0;

      > .col,
      > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
      }

      .selector {
        text-align: center;
      }
    }

    label {
      font-size: 12px;
      font-weight: bold;
    }

    button {
      width: 100%;
      min-width: 0;
      padding: 0;

      &.index-btn {
        font-size: 14px;
        font-weight: bold;
        color: #777;
      }

      &.move-btn {
        svg {
          margin-bottom: -1px;
        }
      }
    }

    .MuiCheckbox-root {
      padding: 0;
    }
  }

  .slide-thumb-wrapper {
    border-radius: 4px;
    display: flex;
    align-items: center;

    .slide-preview {
      background-color: transparent;
      border-radius: 4px;
      width: 180px;
      height: 102px;
      position: relative;

      .library-slide-indicator {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;

        .icon,
        .tri {
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
        }

        .icon {
          z-index: 2;
          transform: rotate(-45deg);
        }

        .tri {
          z-index: 1;
          width: 0;
          height: 0;
          border-width: 20px;
          border-color: #ffd000 #ffd000 transparent transparent;
          border-style: solid;
          border-top-right-radius: 3px;
        }
      }

      &::before {
        border-radius: 4px;
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid $border-color;
      }

      img {
        border-radius: 4px;
      }

      .no-thumb {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 4px;
      }
    }
  }

  .slide-thumb-draft {
    border-radius: 4px;
    // width: 200px;

    .info {
      background-color: transparent;
      border-radius: 4px;
      width: 180px;
      margin-left: 10px;
      position: relative;
      font-size: 17px;
      font-weight: 500;
      color: #777;
      text-align: center;
      padding-right: 25px;
    }
  }

  .drag-wrap {
    border-bottom: 1px solid $border-colour;
    position: relative;
    padding: 15px 0;
    
    &[data-last="true"] {
      margin-bottom: 30px;
    }

    // &.draft {
    //   padding: 0;
    // }

    &.active {
      background-color: $ps-light;

      .slide-thumb-wrapper{
        border: 1px solid #1d9bf1;
      }
    }

    .slide-draft {
      justify-content: center;
    }
  }

  .slide-add {
    position: absolute;
    bottom: -($add-slide-size / 2);
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    height: $add-slide-size;
    padding-left: 50px;
    padding-right: 30px;
    z-index: 5;

    &.top {
      bottom: unset;
      top: -($add-slide-size / 2);
    }

    button {
      width: $add-slide-size;
      height: $add-slide-size;
      min-height: 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .sidebar-save {
    width: 100%;
    padding: 5px;
    height: $save-button-height;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}

// @charset "UTF-8";

// // Default Variables

// // Slick icon entity codes outputs the following
// // "\2190" outputs ascii character "←"
// // "\2192" outputs ascii character "→"
// // "\2022" outputs ascii character "•"

// $slick-font-path: "./fonts/" !default;
// $slick-font-family: "slick" !default;
// $slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
// $slick-dot-color: black !default;
// $slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
// $slick-dot-character: "\2022" !default;
// $slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

// @function slick-image-url($url) {
//     @if function-exists(image-url) {
//         @return image-url($url);
//     }
//     @else {
//         @return url($slick-loader-path + $url);
//     }
// }

// @function slick-font-url($url) {
//     @if function-exists(font-url) {
//         @return font-url($url);
//     }
//     @else {
//         @return url($slick-font-path + $url);
//     }
// }

// /* Slider */

// .slick-list {
//     .slick-loading & {
//         background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
//     }
// }

// /* Icons */
// @if $slick-font-family == "slick" {
//     @font-face {
//         font-family: "slick";
//         src: slick-font-url("slick.eot");
//         src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
//         font-weight: normal;
//         font-style: normal;
//     }
// }

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 50px;
    width: 50px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background-color: white;
    // background: transparent;
    color: #53565a;
    top: 42%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    border-radius: 0!important;
    outline: none;
    z-index: 99;
    &:hover, &:focus {
        outline: none;
        background: #cecece;
        color: black;
        // &:before {
        //     opacity: $slick-opacity-on-hover;
        // }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    // &:before {
    //     // font-family: $slick-font-family;
    //     font-size: 20px;
    //     line-height: 1;
    //     color: $slick-arrow-color;
    //     opacity: $slick-opacity-default;
    //     -webkit-font-smoothing: antialiased;
    //     -moz-osx-font-smoothing: grayscale;
    // }
}

.slick-prev {
    left: 50px;
    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }
    // &:before {
    //     content: $slick-prev-character;
    //     [dir="rtl"] & {
    //         content: $slick-next-character;
    //     }
    // }
}

.slick-next {
    right: 50px;
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }
    // &:before {
    //     content: $slick-next-character;
    //     [dir="rtl"] & {
    //         content: $slick-prev-character;
    //     }
    // }
}

/* Dots */

// .slick-dotted.slick-slider {
//     margin-bottom: 30px;
// }

// .slick-dots {
//     position: absolute;
//     bottom: -25px;
//     list-style: none;
//     display: block;
//     text-align: center;
//     padding: 0;
//     margin: 0;
//     width: 100%;
//     li {
//         position: relative;
//         display: inline-block;
//         height: 20px;
//         width: 20px;
//         margin: 0 5px;
//         padding: 0;
//         cursor: pointer;
//         button {
//             border: 0;
//             background: transparent;
//             display: block;
//             height: 20px;
//             width: 20px;
//             outline: none;
//             line-height: 0px;
//             font-size: 0px;
//             color: transparent;
//             padding: 5px;
//             cursor: pointer;
//             &:hover, &:focus {
//                 outline: none;
//                 &:before {
//                     opacity: $slick-opacity-on-hover;
//                 }
//             }
//             &:before {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 content: $slick-dot-character;
//                 width: 20px;
//                 height: 20px;
//                 font-family: $slick-font-family;
//                 font-size: $slick-dot-size;
//                 line-height: 20px;
//                 text-align: center;
//                 color: $slick-dot-color;
//                 opacity: $slick-opacity-not-active;
//                 -webkit-font-smoothing: antialiased;
//                 -moz-osx-font-smoothing: grayscale;
//             }
//         }
//         &.slick-active button:before {
//             color: $slick-dot-color-active;
//             opacity: $slick-opacity-default;
//         }
//     }
// }

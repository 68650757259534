.notices-actions,
.presentation-actions,
.gallery-actions {
  position: fixed;
  bottom: calc(30px + env(safe-area-inset-bottom)); // iOS safari fix
  right: 30px;
  z-index: 10;

  button {
    width: 50px;
    height: 50px;
    padding: 0;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 50%;

    &:hover {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
    }

    svg {
      width: auto !important;
      height: auto !important;
      margin-right: 0 !important;
    }
  }
}
.file-add-container {
  position: relative;

  .file-add-container-wrapper {
    position: relative;
    height: 60px;
    max-width: 400px;
    margin: 0 auto;
  }

  .filepond--wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    z-index: 10;
  }

  .filepond--root {
    margin-bottom: 0;

    .filepond--credits {
      display: none;
    }
  
    .filepond--drop-label {
      min-height: 60px;
      font-size: 16px;
      font-family: "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }
  }
}
$blue: #1d9bf1;
$grey: #333;
$grey-2: #666;
$grey-3: #999;
$grey-4: #ccc;
$grey-5: #e9e9e9;

$font-size: 0.875rem;

.tabs {
  .tab-list {
    min-width: 400px;
    max-width: 680px;
    background-color: $grey-5;
    border-radius: 12px;
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 4px 8px $grey-4;
  }

  .tab-panel {
    width: 100%;
    font-size: $font-size;
  }

  .tab {
    color: $grey-2;
    cursor: pointer;
    font-size: 0.775rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    padding: 12px;
    border: none;
    display: flex;
    justify-content: center;

    &:first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-of-type {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:hover {
      background-color: $blue;
      color: $white;
    }

    &:focus {
      color: $white;
    }

    &.Mui-selected {
      background-color: $blue;
      color: $white;
    }
  }
}

// tab buttons - quick solution
.MuiButtonBase-root {
  &.MuiTab-root {
    font-size: 16px;
    padding: 8px 12px; // default: 12px 16px;
  }
}
div[data-page="presentations-creator"].container-fluid{
  padding: 0 4rem;
}

.presentations.creator.screen{
    .creator-pagination {
        margin-bottom: 150px;
    }
    .page-header{
      margin-bottom: 0;
      position: relative;
      z-index: 999;
      .page-name, .category-selector, .filter-btn{
        border-bottom: none;
      }

      .page-header__filters{
        .category-select-prf__value-container {
            justify-content: flex-end;
        }


        &>div:nth-of-type(1){
            // max-width: 170px;
        }
        &>div:nth-of-type(2){
            font-weight: 500;
            max-width: 130px;
            svg{
                stroke-width: 3;
            }
        }
      }
        .toggle-switch{
            input{
            background-color: #20bf55;
            border-color: #20bf55;
            }
        }
    }

    .pagination-container{
        .btn{
            @include btn-reset();
            color: #555;
            border-radius: 5px;
            margin: 0 10px;
            
            &.active{
            color: #ffffff;
            background-color: #1d9bf1;
            }
        }
    }

    .MuiPagination-ul{
        justify-content: center;
        padding-top: 30px;
    }
    
    .selected-icon {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 1;
        width: 20px;
        height: 20px;

        @include transition(all .5s ease-in-out);

        .icon,
        .tri {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
        }

        .icon {
            z-index: 2;
        }

        .tri {
            z-index: 1;
            width: 0;
            height: 0;
            border-width: 23px;
            border-color: #4caf50 #4caf50 transparent transparent;
            border-style: solid;
            border-top-right-radius: 3px;
            width: 20px;
            height: 20px;
        }
    }

    .presentation-actions{
        z-index: 15;
        
        button{
            background-color: #1d9bf1;
            border-color: #1d9bf1;
        }
    }
}
// GROUPS

.groups {
  .group-item {
    margin-bottom: 30px;
    padding-bottom: 10px;
    // Flick height = 75%;
    @include border-flick();

    .group-item__content {
      padding: 0 15px;

      .title {
        margin: 0;
        font-weight: 400;
        font-size: 1rem;

        @include line-clamp(2)
      }
    }

    button {
      @include btn-reset();
      @include verticallyCenter();
      font-size: 0.8rem;

      &:active {
        @include btn-reset();

        &.delete {
          color: $gradient-pink;
        }
  
        &.edit {
          color: $primary-colour;
        }
      }

      svg {
        width: 0.8rem; 
        min-width: 0.8rem; 
        height: auto; 
        margin-right: 0.25rem;
      }

      &.delete {
        color: $gradient-pink;
        @include transition-colour();

        &:hover {
          color: darken($gradient-pink, 10%);
        }
      }

      &.edit {
        color: $primary-colour;
        @include transition-colour();

        &:hover {
          color: $secondary-colour;
        }
      }

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

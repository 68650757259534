// Import all
@import './abstracts/all';
@import './base/all';
@import './partials/all';
@import './layouts/all';
@import './screens/all';

// MUI
@import './mui/all';

// Reveal
@import './reveal/reveal.scss';
@import './reveal/layout.scss';
@import './reveal/print/paper.scss';
@import './reveal/print/pdf.scss';
@import './reveal/theme/source/companyapp.scss';

// Editorjs
@import './editor/main.scss';

// Slick
@import '~slick-carousel/slick/slick.css'; 
@import '~slick-carousel/slick/slick-theme.css';

// Modules
@import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.scss';
@import './plugins/all';

// Presentation themes
@import 'https://files-radisson.companyapp.net/cms-radisson/themes/all.css';
// @import 'https://app_files.companyapp.net/newapp/themes/all.css';
// @import 'https://dev-files.companyapp.net/dev-cms-radisson/themes/all.css';

// PODCASTS
.podcasts {

  .podcasts-item {
    display: flex;
    flex-direction: column;

    video {
      display: block;
    }

    .podcasts-item__content {
      @media (min-width: $bp-lg) {
        padding-left: 0;
      }

      // Same as _screenItem.scss
      .category {
        display: flex;
        align-items: center;
        color: $primary-colour;

        svg {
          width: 1rem;
          min-width: 1rem;
          height: auto;
          margin-left: 0.5rem;
        }

        .category__text {
          margin-left: 0.25em;
          font-size: 0.8rem;

          @media (min-width: $bp-sm) {
            font-size: inherit;
          }

          @media (min-width: $bp-lg) {
            font-size: 0.8rem;
          }
        }

      }

      .play-icon {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #fff;
        background-color: $primary-colour;
        transition: background-color 250ms ease-in-out;

        svg {
          width: 15px;
          height: auto;
          margin-left: 2px; // better alignment
        }
      }

    }

    .podcasts-item__footer {
      // Flick height = 75%;
      @include border-flick();
    }

    &.hovered {
      .play-icon {
        background-color: $secondary-colour;
        transition: background-color 250ms ease-in-out;
      }
    }

  }

}
// All font imports
@font-face {
  font-family: "Roboto";
  src: url("./../../fonts/Roboto-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("./../../fonts/Roboto-LightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("./../../fonts/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("./../../fonts/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("./../../fonts/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
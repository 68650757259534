// MIXINS

@mixin gradient() {
  background-image:
    linear-gradient(
      45deg, 
      $gradient-colours
    );
}

// Remove default btn styles
@mixin btn-reset() {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 0;

  &:focus,
  &:active,
  &:active:focus {
    background-color: transparent;
    border: none;
    box-shadow: initial;
  }
}

// CSS truncate to number of lines
@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
}

// Adds a 'flick' to the bottom right corner
@mixin border-flick($height: 75%) {
  border-bottom: 1px solid $border-colour;
  border-right: 1px solid $border-colour;
  border-bottom-right-radius: 5px;
  position: relative;
  transition: border 250ms ease-in-out;

  &::after {
    content: "";
    width: 3px;
    height: $height;
    position: absolute;
    top: 0;
    right: -1px;
    background-color: #fff;
    @content;
  }
}

// Custom scrollbar (nav & messages are using)
@mixin custom-scrollbar() {
  ::-webkit-scrollbar {
    // width: 12px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $scrollbar-colour;
    border-radius: 20px;
    // border: 2px solid transparent;
    background-clip: content-box;

    &:hover {
      background-color: darken($scrollbar-colour, 5%);
    }
  }

  // Firefox custom scrollbar implementation
  scrollbar-color: $scrollbar-colour transparent;
  scrollbar-width: thin;

  @content;
}


// Transform buttons on hover
@mixin transformY-onHover($amount: -3px, $duration: 250ms) {
  transform: translateY(0);
  transition: transform $duration ease-in-out;
  // animation: moveUp 500ms forwards;

  &:hover {
    transform: translateY($amount);
    transition: transform $duration ease-in-out;
  }

  // @keyframes moveUp {
  //   0% {
  //     transform: translateY(0%);
  //   }
  
  //   50% {
  //     transform: translateY(-3px);
  //   }

  //   100% {
  //     transform: translateY(0%);
  //   }
  // }

}

// Transition scale
@mixin transition-scale($amount, $duration: 500ms) {
  transform: scale($amount);
  transition: transform $duration ease-in-out;
  will-change: transform;
}

// Transition colour
@mixin transition-colour($duration: 250ms) {
  transition: color $duration ease-in-out;
}

// Vertical alignment
@mixin verticallyCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

// Transition
@mixin transition($x...){
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}

// Animation
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
      @content;
  }
  @-moz-keyframes #{$name} {
      @content;
  }
  @-ms-keyframes #{$name} {
      @content;
  }
  @keyframes #{$name} {
      @content;
  }
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}
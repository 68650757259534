// CONTACT SINGLE
$image-size: 100px;
$image-lg-size: 150px;

.single {
  &.contact {
    .article {
      .contact__header {
        margin-bottom: 2rem;

        .user-image {
          width: $image-size;
          height: $image-size;
          margin: 0 auto;

          @media (min-width: $bp-lg) {
            width: $image-lg-size;
            height: $image-lg-size;
          }

          svg {
            width: $image-size / 2;
            height: auto;
          }
        }

        h1,
        h3 {
          margin-bottom: 0;
        }

        h3 {
          font-weight: 300;
        }
      }

      .contact__body {
        @media (min-width: $bp-lg) {
          margin-left: $image-lg-size + 30px;
        }
      }

      .contact__group {
        h6 {
          margin-bottom: 0;
        }

        .btn {
          width: 45px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          border-radius: 50%;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .contact__message {
        margin-top: 3rem;

        textarea {
          width: 100%;
          border-radius: 0.25rem;
          border-color: $light-font-colour;
          background-color: transparent;
        }
      }
    }
  }
}

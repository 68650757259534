// FORUM TOPICS
$icon-size: 20px;

.forums-topics {
  .page-header {
    margin-bottom: 0.5rem;
  }

  .forums-board-description {
    margin-bottom: 2rem; // same as default .page-header
  }

  .add-topic {
    @include btn-reset();
    display: flex;
    font-size: 0.8rem;
    color: $primary-colour;

    .add-topic__icon {
      width: $icon-size;
      display: flex;
      margin-right: 0.25rem;
    }
  }

  .forum-topics__header {
    padding-bottom: 0.25rem;
    border-bottom: 1px solid $border-colour;

    .heading {
      margin-bottom: 0;
      font-size: 0.7rem;
    }
  }

  .forum-topic {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px dashed $border-colour;
    font-size: 0.8rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .link {
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    color: inherit;
    color: $primary-colour;

    &:hover {
      color: $secondary-colour;
    }
  }

  .forum-topic__icon {
    flex: 0 0 calc(0.8rem * 1.5); // Alignment with title line-height
    height: calc(0.8rem * 1.5); // Alignment with title line-height
    display: flex;
    align-items: center;
    margin-right: 0.25rem;

    svg {
      width: $icon-size;
      height: $icon-size;
    }
  }

  .forum-topic__title {
    @include line-clamp(2);
    font-weight: 400;
  }

  .forum-topic__last-contributor {
    display: flex;
  }
}
